import React from 'react'
import styled from 'styled-components'
import useMobile from '../hooks/useMobile'
import StyledContainer from './Styled'
import { Image } from './Preset'
import Heco from '../assets/images/Heco.svg'
import BSC from '../assets/images/BSC.svg'

import ava from '../assets/images/ava.svg'
import zb from '../assets/images/zb.svg'
import mxc from '../assets/images/mxc.svg'
import huobi from '../assets/images/huobi.svg'
import duckstarter from '../assets/images/duckstarter.svg'

import { FiBookOpen } from 'react-icons/fi'



const Card = styled(StyledContainer)`
    width: 330px;
    height: 390px;
    // height: 100%;
    display: flex; 
    flex-direction: column; 
    align-items: center;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    // background: linear-gradient(180deg, #342A50 0%, rgba(52, 42, 80, 0) 100%);
    background: linear-gradient(180deg, #342A50 0%, rgba(52, 42, 80, 0.8) 100%);
    border-radius: 30px;
`

const Grid = styled(StyledContainer)`
    width: 100%;
    display: grid;
    grid-gap: 59px 29px;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 330px);
`

const Tag = styled(StyledContainer)`
    width: 30px;
    height: 18px;
    // background: #6425C5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Button = styled.button`
    padding: 10px 0;
    font-family: GTText;
    background:  ${({haveBackground,BKC})=> ! haveBackground ? "transparent" : BKC ? "#ACA4B1":"#6425C5" };
    border: ${({haveBackground})=> ! haveBackground ? "2px solid #6425C5" : "none" }; 
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%; 
    height: 100%;
    font-size: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
`

const cardsList = [
    {
        type: 'IDO',
        name: 'Avalaunch',
        time: '10.4  07:00  UTC',
        chain: 'AVAX C-Chain',
        src: ava,
        href: 'https://medium.com/avalaunch/hurricaneswap-x-avalaunch-ido-announcement-9834808f2e4',
        idoHref: null,
        close: false
    },
    {
        type: 'IEO',
        name: 'ZB.com',
        time: '9.26  14:00  UTC',
        chain: 'AVAX C-Chain',
        src: zb,
        href: 'https://www.zb.com/en/message/1039',
        idoHref: 'https://www.zb.com/en/',
        close: false
    },
    {
        type: 'IEO',
        name: 'MEXC',
        time: '9.26  14:00  UTC',
        chain: 'AVAX C-Chain',
        src: mxc,
        href: 'https://support.mexc.com/hc/en-001/articles/4406905454745-The-New-M-Day-8th-Session-HurricaneSwap-HCT-is-Launching',
        idoHref: 'https://www.mexc.com/mday/detail/5cf4e727f52d412b84007f9875e5214c',
        close: false
    },
    {
        type: 'IDO',
        name: 'WeStarter',
        time: '9.27  12:00  UTC',
        chain: 'Huobi Eco Chain',
        src: huobi,
        href: 'https://westarter.medium.com/westarter-heco-will-launch-hurricaneswap-on-september-26th-at-22-00-sgt-abc2c2b4861d',
        idoHref: 'https://www.westarter.org/#/',
        close: false
    },
    {
        type: 'IDO',
        name: 'DuckSTARTER',
        time: '9.26  14:00  UTC',
        chain: 'Binance Smart Chain',
        src: duckstarter,
        href: 'https://medium.com/duckdao/hurricaneswap-duckstarter-refundable-ido-d3b7818e1188',
        idoHref: 'https://duckstarter.io/pool/hurricaneswap',
        close: true
    }
]

const Name = ({ src, name, ifMobile }) => {
    return (
        <StyledContainer flex alignItems='center'>
            <StyledContainer width='24px'>
                <Image src={src}></Image>
            </StyledContainer>
            <StyledContainer fontWeight={500} ml={ifMobile ? '5px' : '16px'} fontSize='24px'>{name}</StyledContainer>
        </StyledContainer>

    )
}

export default () => {
    const ifMobile = useMobile(900)

    return (
        <StyledContainer zIndex='3' position='relative' mb='66px' mt={ifMobile ? '177px' : '23px'} flex column alignItems='center' justifyContent='center'>
            <StyledContainer mb='66px' fontWeight='bold' color='#fff' fontSize={ifMobile ? '30px' : '40px'}>Other ways</StyledContainer>
            <StyledContainer pl='20px' pr='20px' maxWidth='1034px' width='100vw' flex justifyContent='center' column alignItems='center'>
                {cardsList.map(i => (
                    ifMobile
                        ? (
                            <StyledContainer position='relative' color='#fff' key={i.name} p='25px 21px' flex column justifyContent='center' width='320px' height='181px' mb='24px' borderRadius='20px' background='linear-gradient(180deg, #342A50 0%, rgba(52, 42, 80, 0.8) 100%)'>

                                <StyledContainer position='absolute' top='0' left='0'>
                                    <StyledContainer flex alignItems='center' justifyContent='center' width='50px' height='24px' borderRadius='20px 0' background={i.type === 'IDO' ? '#6425C5' : '#70608A'} fontSize='12px'>{i.type}</StyledContainer>
                                </StyledContainer>

                                <StyledContainer flex alignItems='center' justifyContent='space-between' width='100%'>
                                    <StyledContainer flex column mt={i.href && '25px'}>
                                        <Name ifMobile={ifMobile} src={i.src} name={i.name}></Name>
                                        <StyledContainer mt='5px' color='rgba(255, 255, 255, 0.85)' fontSize='16px' flex alignItems='center'>{i.time}</StyledContainer>
                                        <StyledContainer mt='5px' color='rgba(255, 255, 255, 0.65)' fontSize='12px'>{i.chain}</StyledContainer>
                                    </StyledContainer>

                                    <StyledContainer width='80px' mt={i.idoHref && '35px'}>
                                        <Button disabled={!i.idoHref} haveBackground={i.idoHref}>
                                            <a style={{ color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }} href={i.idoHref} target='_blank'>{i.idoHref ? 'GO!' : 'Coming Soon'}</a>
                                        </Button>
                                    </StyledContainer>
                                </StyledContainer>
                                {
                                    i.href && (
                                        <StyledContainer mt='25px' flex alignItems='center'>
                                            <FiBookOpen size='24px' color='#fff'></FiBookOpen>
                                            <a style={{ fontSize: '16px', textDecoration: 'underline', marginLeft: '12px', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }} href={i.href} target='_blank'>Guide</a>
                                        </StyledContainer>
                                    )
                                }




                            </StyledContainer>
                        )
                        : (
                            <StyledContainer key={i.name} justifyContent='space-between' flex mb='20px' p='26px 91px' borderRadius='12px' background='rgba(52, 42, 80, 0.9)' width='100%' color='#fff' position='relative'>

                                <StyledContainer flex column>
                                    <StyledContainer width='350px' flex alignItems='center'>
                                        <StyledContainer flex alignItems='center' mr='16px'><Tag background={i.type === 'IDO' ? '#6425C5' : '#70608A'} fontSize='12px'>{i.type}</Tag></StyledContainer>

                                        <StyledContainer flex column>
                                            <Name src={i.src} name={i.name}></Name>
                                        </StyledContainer>

                                        <StyledContainer flex1={1} justifyContent='end' color='rgba(255, 255, 255, 0.85)' fontSize='16px' flex alignItems='center'>{i.time}</StyledContainer>
                                    </StyledContainer>
                                    <StyledContainer mt='5px' pl='44px' color='rgba(255, 255, 255, 0.65)' fontSize='12px'>{i.chain}</StyledContainer>
                                </StyledContainer>

                                <StyledContainer flex alignItems='center'>
                                    {
                                        i.href && (
                                            <StyledContainer mr='22px' flex alignItems='center'>
                                                <FiBookOpen size='24px' color='#fff'></FiBookOpen>
                                                <a style={{ textDecoration: 'underline', marginLeft: '10px', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }} href={i.href} target='_blank'>Guide</a>
                                            </StyledContainer>
                                        )
                                    }
                                    <StyledContainer width='205px' flex alignItems='center'>
                                        <Button disabled={!i.idoHref && i.close} haveBackground={i.idoHref} BKC={i.close}>
                                            <a style={{ color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }} href={i.idoHref} target='_blank'>{i.close ? 'Closed' : i.idoHref ? 'GO!' : 'Coming Soon'}</a>
                                        </Button>
                                    </StyledContainer>
                                </StyledContainer>

                            </StyledContainer>
                        )
                ))}
            </StyledContainer>
            {/* <Grid>
                <Card>
                    <StyledContainer position='relative' zIndex='3' width='61px' height='61px' mt='54px' mb='55px'>
                        <Image src='https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png'></Image>
                    </StyledContainer>
                    <StyledContainer position='relative' zIndex='3' mb='32px'>Avalaunch</StyledContainer>
                    <StyledContainer position='relative' zIndex='3'>MEXC</StyledContainer>
                </Card>

                <Card>
                    <StyledContainer position='relative' zIndex='3' width='61px' height='61px' mt='54px' mb='55px'>
                        <Image src={Heco}></Image>
                    </StyledContainer>
                    <StyledContainer position='relative' zIndex='3' mb='32px'>WeStarter</StyledContainer>
                </Card>

                <Card>
                    <StyledContainer position='relative' zIndex='3' width='61px' height='61px' mt='54px' mb='55px'>
                        <Image src={BSC}></Image>
                    </StyledContainer>
                    <StyledContainer position='relative' zIndex='3' mb='32px'>DuckSTARTER</StyledContainer>
                </Card>

            </Grid> */}
        </StyledContainer >
    )
}