import React from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const StyledSVG = styled.div`
  animation: 2s ${rotate} linear infinite;
  height: 36px;
  width: 36px;
`

export default () => {
    return (
        <StyledSVG>
            <svg width="100%" height="100%" viewBox="0 0 104 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M102 48.4004V53.0004C101.994 63.7825 98.5025 74.2738 92.0467 82.9095C85.5908 91.5453 76.5164 97.8628 66.1768 100.92C55.8372 103.977 44.7863 103.61 34.6724 99.8733C24.5584 96.1368 15.9233 89.2309 10.0548 80.1858C4.18633 71.1406 1.39896 60.4407 2.10838 49.682C2.81781 38.9232 6.98603 28.6821 13.9914 20.4858C20.9968 12.2895 30.4639 6.57733 40.9809 4.20115C51.498 1.82497 62.5013 2.91211 72.35 7.30042" stroke="#4BDD97" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </StyledSVG>

    )
}