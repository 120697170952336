import styled, { css } from "styled-components"
import ReactDOM from 'react-dom';
import StyledContainer from "./Styled"
import React, { useState, useEffect } from 'react'
import useMobile from "../hooks/useMobile"

const Container = styled(StyledContainer)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${({ show }) => (show ? '20' : '-20')};
    opacity: ${({ show }) => (show ? '1' : '0')};
    display: flex;
    align-items: ${({ ifMobile }) => (ifMobile ? 'flex-end' : 'center')};
    justify-content: center;
    background: rgba(0,0,0,0.5);
    width: 100vw;
    height: 100vh;
    transition: all linear 0.2s;

    
`

const AnimationContainer = styled(StyledContainer)`
    transition: transform linear .2s;
    transform: ${({ show, ifMobile }) => (ifMobile ? (show ? 'translateY(0%)' : 'translateY(100vh)') : 'none')};

`

export default ({ dismiss, show, setShow, children }) => {
    const ifMobile = useMobile(465)

    // const [ifShow, setIfShow] = useState(show)

    // useEffect(() => {
    //     setIfShow(show)
    // }, [show])

    const handleClick = (e) => {
        e.stopPropagation();
        const tar = e.target;
        const _ = document.getElementById('modal-content')
        if (_ && !_.contains(tar)) {
            // setIfShow(false)
            setShow(false)
        }
    }

    // if (show) {
    const _ = (
        <Container show={show} ifMobile={ifMobile} onClick={handleClick}>
            {/* { */}
                {/* show ? ( */}
                    <AnimationContainer ifMobile={ifMobile} show={show}>
                        <StyledContainer id='modal-content' color='#fff' padding='24px' flex column alignItems='center' justifyContent='center' maxWidth='464px' width='100vw' height={ifMobile ? '490px' : '330px'} borderRadius={!ifMobile && '20px'} ifMobile={ifMobile && `border-radius: 20px 20px 0 0`} background='#342A50'>
                            {React.cloneElement(children, {dismiss: dismiss})}
                        </StyledContainer>
                    </AnimationContainer>

                {/* ) : null */}
            {/* } */}
        </Container>
    )

    return ReactDOM.createPortal(_, document.getElementById('root'))
    // }

    // return null
}