import React from 'react'
import styled from 'styled-components'
import StyledContainer from './Styled'
import addressAmount from '../assets/images/addressAmount.svg'
import transactionAmount from '../assets/images/transactionAmount.svg'
import { Image } from './Preset'
import Brush from '../assets/images/Union.svg'

const BoxContainer = styled(StyledContainer)`
max-width: 546px;
width: 100%;
// height: 118px;
background: #6425C5;
position: relative;
:after{
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    left: 15%;
    bottom: -10px;
    background: #6425C5;
    transform: rotate(45deg);
}
`


const Grid = styled(StyledContainer)`
    width: 100%;
    display: grid;
    // grid-gap: ${({ ifMobile }) => (ifMobile ? '69px' : '116px')};
    grid-gap: 56px;
    justify-content: ${({ ifMobile }) => (ifMobile ? 'center' : 'space-between')};
    grid-template-columns: ${({ gridTemplateColumns }) => (gridTemplateColumns ? gridTemplateColumns : `repeat(auto-fill, 440px)`)};
`

export default ({ ifMobile }) => {
    return (
        <StyledContainer mt='58px' mb={ifMobile ? '99px' : '150px'} p={ifMobile ? '0 20px' : 0}>
            <BoxContainer mb={ifMobile ? '61px' : '104px'} height={ifMobile ? '82px' : '118px'} mr={ifMobile ? 'auto' : '0'} ml={ifMobile ? 'auto' : '100px'} flex alignItems='center' justifyContent='center' color='#fff' fontWeight='bold' fontSize={ifMobile ? '30px' : '40px'}>Testnet Launched</BoxContainer>

            <StyledContainer flex wrap alignItems='center' justifyContent='center'>


                <Grid ifMobile={ifMobile} p={ifMobile ? '0 28px' : '0 131px'} gridTemplateColumns={ifMobile ? 'repeat(auto-fill, 1fr)' : 'repeat(auto-fill, 440px)'}>
                    <StyledContainer position='relative' color='#fff' flex alignItems='center' maxWidth='558px' width='100vw'>
                        <StyledContainer transform={ifMobile ? 'rotate(-30deg) scale(0.9)' : 'rotate(-30deg)'}><Image src={Brush}></Image></StyledContainer>
                        <StyledContainer flexDirection={ifMobile ? 'column' : 'row'} position='absolute' top='50%' left='50%' transform='translate(-50%, -50%)' justifyContent='center' flex alignItems='baseline'>
                            <StyledContainer flex alignItems='baseline'>
                                <StyledContainer fontWeight='bold' fontSize='60px'>191,855&nbsp;</StyledContainer>
                            </StyledContainer>
                            <StyledContainer flex alignItems='baseline'>
                                <StyledContainer mt='15px' color='rgba(255,255,255,0.6)' fontSize='28px'>HCT&nbsp;</StyledContainer>
                                <StyledContainer mt='15px' color='rgba(255,255,255,0.6)' fontSize='20px'>addresses</StyledContainer>
                            </StyledContainer>
                        </StyledContainer>
                    </StyledContainer>

                    <StyledContainer flex column alignItems='center'>
                        <StyledContainer mb='56px' color='#fff' justifyContent='space-between' flex alignItems='center'>
                            <StyledContainer width={ifMobile && '135px'} flex column>
                                <StyledContainer flex wrap alignItems='baseline'>
                                    <StyledContainer fontWeight='bold' fontSize='40px'>35.1%&nbsp;</StyledContainer>
                                    {/*<StyledContainer color='rgba(255,255,255,0.6)' fontSize='20px'>(546200)</StyledContainer> */}
                                </StyledContainer>
                                <StyledContainer mt='15px' color='rgba(255,255,255,0.6)' fontSize='12px'>of total FUJI Testnet addresses (All time) </StyledContainer>
                            </StyledContainer>
                            <StyledContainer width={ifMobile ? '140px' : '200px'}>
                                <Image src={addressAmount}></Image>
                            </StyledContainer>
                        </StyledContainer>

                        <StyledContainer color='#fff' justifyContent='space-between' flex alignItems='center'>
                            <StyledContainer width={ifMobile && '135px'} flex column>
                                <StyledContainer flex wrap alignItems='baseline'>
                                    <StyledContainer fontWeight='bold' fontSize='40px'>83.4%&nbsp;</StyledContainer>
                                   {/* <StyledContainer color='rgba(255,255,255,0.6)' fontSize='20px'>(377464)</StyledContainer> */}
                                </StyledContainer>
                                <StyledContainer mt='15px' color='rgba(255,255,255,0.6)' fontSize='12px'>of total FUJI Testnet transaction since Testnet launched </StyledContainer>
                            </StyledContainer>
                            <StyledContainer width={ifMobile ? '140px' : '200px'}>
                                <Image src={transactionAmount}></Image>
                            </StyledContainer>
                        </StyledContainer>


                    </StyledContainer>
                    {/* 
                <StyledContainer color='#fff' flex alignItems='center'>
                    <StyledContainer justifyContent='center' flex column>
                        <StyledContainer flex alignItems='baseline'>
                            <StyledContainer fontWeight='bold' fontSize='40px'>40000+</StyledContainer>
                        </StyledContainer>
                        <StyledContainer mt='15px' color='rgba(255,255,255,0.6)' fontSize='12px'>offer test-AVAX for 40000+ addresses</StyledContainer>
                    </StyledContainer>
                </StyledContainer>

                <StyledContainer color='#fff' flex alignItems='center'>
                    <StyledContainer justifyContent='center' flex column>
                        <StyledContainer flex alignItems='baseline'>
                            <StyledContainer fontWeight='bold' fontSize='40px'>$51,752,308</StyledContainer>
                        </StyledContainer>
                        <StyledContainer mt='15px' color='rgba(255,255,255,0.6)' fontSize='12px'>Farm TVL (Estimated based on real token price)</StyledContainer>
                    </StyledContainer>
                </StyledContainer>
                */}
                </Grid>

            </StyledContainer>



        </StyledContainer>
    )
}