import StyledContainer from "./Styled"
import { FiX } from 'react-icons/fi'
import useMobile from "../hooks/useMobile"
import success from '../assets/images/success-icon.svg'
import error from '../assets/images/error-icon.svg'
import { Image } from "./Preset"

export default ({errorMsg, status, dismiss}) => {
    const mobileSize = useMobile(465)

    return (
        <StyledContainer width='100%' height='100%' flex justifyContent='space-between' column>
            <StyledContainer width='100%' color='#fff' fontSize='20px' flex alignItems='center'>
                <StyledContainer ml='20px' flex1={1} flex alignItems='center' justifyContent='center'>{status ? 'Success' : 'Error'}</StyledContainer>
                <StyledContainer pointer>
                    <FiX onClick={dismiss}></FiX>
                </StyledContainer>
            </StyledContainer>
            <StyledContainer flex1='1' flex column alignItems='center' justifyContent='center'>
                <StyledContainer width='120px' margin="20px">
                    <Image src={status ? success : error}></Image>
                </StyledContainer>
                <StyledContainer >{status ? <StyledContainer><p>Claim successfully!</p><p>Check it in your wallet</p></StyledContainer> : errorMsg ? errorMsg : 'Claim failed, try again.'}</StyledContainer>
            </StyledContainer>

        </StyledContainer>


    )
}