import React, {useLayoutEffect, useRef} from 'react'
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import { useSize } from 'ahooks'

am4core.useTheme(am4themes_animated)

const json = [
  {
    "type": "Yield Farming",
    "value": 60,
    "weight": 5,
    "color": "#6425C5"
  },
  {
    "type": "Growth Fund",
    "value": 4,
    "weight": 4,
    "color": "#FD5998"
  },
  {
    "type": "HurricaneAlliance",
    "value": 10,
    "weight": 7,
    "color": "#2B65FC"
  },
  {
    "type": "Team",
    "value": 10,
    "weight": 5,
    "color": "#FA795C"
  },
  {
    "type": "Private 1",
    "value": 11,
    "weight": 6,
    "color": "#44D4DD"
  },
  {
    "type": "Private 2",
    "value": 4,
    "weight": 3,
    "color": "#C261D2"
  },
  {
    "type": "IDO",
    "value": 1,
    "weight": 6.5,
    "color": "#FFC65B"
  }, 
]

const colorList = [
  am4core.color("#6425C5"),
  am4core.color("#FD5998"),
  am4core.color("#384BDE"),
  am4core.color("#FA795C"),
  am4core.color("#44D4DD"),
  am4core.color("#C361D2"),
  am4core.color("#FFC65B")
]


export default ({ifMobile})=>{
    const containerRef = useRef()
    const chart = useRef(null)
    const {width} = useSize(containerRef.current)
   // console.log('width', width)

    useLayoutEffect(() => {
        let chart = am4core.create("chartdiv", am4charts.PieChart)
        chart.innerRadius = am4core.percent(30)
        chart.data = json

        let pieSeries = chart.series.push(new am4charts.PieSeries())
        pieSeries.dataFields.value = "value"
        pieSeries.dataFields.radiusValue = "weight"
        pieSeries.dataFields.category = "type"
        pieSeries.colors.list = colorList
        pieSeries.slices.template.cornerRadius = 3
        pieSeries.hiddenState.properties.endAngle = -90
        pieSeries.labels.template.fontSize = ifMobile ? 12 : 16
        pieSeries.labels.template.text = '{value}%'
        pieSeries.labels.template.radius = '6%'
        pieSeries.alignLabels = false
        pieSeries.x = ifMobile ? -15 : 0
        pieSeries.scale = ifMobile ? 1 : 1.1
        pieSeries.paddingLeft = 25

        pieSeries.labels.template.adapter.add("fill", function(color, target, type, b) {
            if(target?.dataItem?.dataContext?.color){
              return target?.dataItem?.dataContext?.color
            }
            return '#fff'
        })

        chart.legend = new am4charts.Legend()
      
        // chart.legend.paddingLeft = ( width - 330 ) / 2

        chart.legend.position = ifMobile ? 'bottom' : 'left' 
        chart.legend.paddingTop = 10
        chart.legend.maxWidth = ifMobile ? 330 : undefined
        chart.legend.width = ifMobile ? 330 : undefined
        // chart.legend.background.fillOpacity = ifMobile ? 1 : 0
        chart.legend.background.fill = 'rgba(68, 58, 103, 1)'
        chart.legend.align = 'center'

        chart.legend.markers.template.width = ifMobile ? 60 : 100
        chart.legend.markers.template.height = 20
        chart.legend.markers.template.marginRight = 5

        chart.legend.labels.template.fontSize = ifMobile ? 16 : 20
        chart.legend.labels.template.fill = '#fff'
        chart.legend.valueLabels.template.fontSize = ifMobile ? 16 : 20
        chart.legend.valueLabels.template.fill = '#fff'
        chart.legend.valueLabels.template.align = 'right'
        chart.legend.valueLabels.template.paddingLeft = 50

        chart.legend.itemContainers.template.paddingTop = ifMobile ? 15 : 18;
        chart.legend.itemContainers.template.paddingBottom = ifMobile ? 15 : 18;


        return () => {
            chart.dispose()
        }
      }, [ifMobile, width])
    

    return (
        <div ref={containerRef} id="chartdiv" style={{ width: "100%", height: ifMobile ? "750px" : '500px'}}></div>
    )
}