import React, { useState, useEffect } from 'react'
import Footer from '../components/Footer'
import Claim from '../components/Claim'
import OtherWays from '../components/OtherWays'
import StyledContainer from '../components/Styled'
import TestLaunched from '../components/TestLaunched'
import TokenDistribution from '../components/TokenDistribution'
import useMobile from '../hooks/useMobile'
import IDOTop from '../assets/images/IDOTop.svg'
import IDODown from '../assets/images/IDODown.svg'
import { Image } from '../components/Preset'
import intersect from '../assets/images/intersect.svg'
import intersectDot from '../assets/images/intersectDot.svg'
import spIntersect from '../assets/images/spIntersect.svg'
import spIntersectDot from '../assets/images/spIntersectDot.svg'
import left from '../assets/images/left.svg'
import right from '../assets/images/right.svg'
import exclude from '../assets/images/exclude.svg'
import styled from 'styled-components'
import bottom from '../assets/images/bottom.svg'
import Investor from '../components/Investor'
import { FiX } from 'react-icons/fi'
import Notice from '../components/notice'

const ScrollInside = styled(StyledContainer)`
    // max-height: 100vh;
    // overflow-y: scroll;

`
const Button = styled.button`
    background: #6425C5;
    box-sizing: border-box;
    border-radius: 8px;
    width: 206px;
    height: 52px;
    font-size: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top:10px;
`
const Text = styled.text`
    font-family: GT Eesti Pro Text;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom:10px;
    text-align:justify;

`
const Modal = styled(StyledContainer)`
   // transition: transform linear .2s;
  //  transform: ${({ show, ifMobile }) => (ifMobile ? (show ? 'translateY(0%)' : 'translateY(100vh)') : 'none')};
   position:fixed;
   display: ${({ show }) => (show ? 'block' : 'none')};
   z-index:20;
   top:100px;
   left:0;
   right:0;
   width:360px;
   margin:0 auto;
   padding:24px;
   
`

const FullHeight = styled(StyledContainer)`
    // overflow: hidden;
    // height: 100vh;
`

export default () => {
    const ifMobile = useMobile()
    const [show, setShow] = useState(false)
    const closeModal = () => {
        setShow(false)
    }
    const showModal = () => {
        setShow(true)
    }

    return (
        <div style={{ position: "relative" }}>

            <Modal show={show} height='600px' flex column alignItems='center' justifyContent='center' height='450px' borderRadius='20px' background='#342A50'>
                <StyledContainer width='100%' color='#fff' fontSize='20px' flex alignItems='center' >
                    <StyledContainer flex1={1} flex alignItems='center' justifyContent='center'>Note</StyledContainer>
                    <StyledContainer pointer>
                        <FiX onClick={closeModal}></FiX>
                    </StyledContainer>
                </StyledContainer>
                <StyledContainer width='100%' flex1={1} flex column justifyContent='center' alignItems='center'>
                    <StyledContainer width='90%' flex1={1} flex column justifyContent='center' alignItems='center' mt='10px'>
                        <Text>Users from the following countries or regions will not be able to participate in token sales on the HurricaneSwap:</Text>
                        <Text>Belarus, Mainland China, Democratic Republic of Congo, Cuba, Iraq, Iran, North Korea, Sudan, Syria, United States
                            of America and its territories (American Samoa, Guam, the Northern Mariana Islands,
                            Puerto Rico, and the U.S. Virgin Islands), Zimbabwe.</Text>
                    </StyledContainer>
                    <Button onClick={closeModal}> Confirm </Button>
                </StyledContainer>
            </Modal>

            <StyledContainer overflow='hidden' position='relative' width='100%' height='100%' background='linear-gradient(180deg, #1F1831 0%, #2D214D 100%)'>
                <StyledContainer flex column justifyContent="end" height="100%" overflow='hidden' position='relative' width='100%'  background='linear-gradient(180deg, #1F1831 0%, #2D214D 100%)'>

                    <StyledContainer width='100%' height='100%' position='absolute' top='0' left='0' right='0' backgroundSize='contain' background={`url(${IDOTop}) no-repeat`}></StyledContainer>

                    <StyledContainer position='relative' zIndex='2' m='auto' fontSize={ifMobile ? '2.15vw' : '16px'}>
                        <FullHeight position='relative' maxWidth='1366px' m='auto'>
                            <ScrollInside>
                                <Claim ifMobile={ifMobile} />
                            </ScrollInside>
                        </FullHeight>
                       
                    </StyledContainer>

                    <Footer ifMobile={ifMobile} />

                    <StyledContainer position='absolute' left='0' right='0' bottom='0'>
                        {/* <Image src={IDODown}></Image> */}
                        {/* <Image src={bottom}></Image> */}
                    </StyledContainer>
                </StyledContainer>
            </StyledContainer>
        </div>

    )
}