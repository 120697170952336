import { baseChainID } from "../constants"

export const CHAIN_LIST = [
    {
        chainId: `0xa869`,// A 0x-prefixed hexadecimal chainId
        chainName: 'Avalanche FUJI C-Chain',
        nativeCurrency: {
            name: 'Avalanche',
            symbol: 'AVAX',
            decimals: 18
        },
        rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
        blockExplorerUrls: ['https://cchain.explorer.avax-test.network']
    },
    {
        chainId: `0xa86a`,// A 0x-prefixed hexadecimal chainId
        chainName: 'Avalanche Mainnet C-Chain',
        nativeCurrency: {
            name: 'Avalanche',
            symbol: 'AVAX',
            decimals: 18
        },
        rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
        blockExplorerUrls: ['https://cchain.explorer.avax.network/']
    }
]

export const setupNetwork = async (_chainId = baseChainID) => {
    const provider = (window).ethereum
    if (provider) {
        const chainId = typeof (_chainId) === "number" ? _chainId || baseChainID : baseChainID
        const currentRPC = CHAIN_LIST.filter(i => i.chainId === `0x${chainId.toString(16)}`)

        try {
            // @ts-ignore
            const res = await provider.request({
                method: 'wallet_addEthereumChain',
                params: [
                    ...currentRPC
                ]
            })
            return true
        } catch (error) {
            return false
        }
    } else {
        console.error('Can\'t setup the network on metamask or others, because window.ethereum is undefined')
        return false
    }
}