import {useSize} from 'ahooks'
import {useMemo} from 'react'

export default (customizedSize = 500)=>{
  const _dom = document.body

  const size= useSize(_dom)

  return useMemo(() => {
    if(size?.width < customizedSize){
      return true
    }
    return false
  }, [size, customizedSize]);
}
