import React from 'react'
import styled from 'styled-components'
import Pie from './Pie'
import StyledContainer from './Styled'

const Box = styled(StyledContainer)`
max-width: 1166px;
width: 100%;
// min-height: 596px;
// height: 1000px;

background: rgba(68, 58, 103, 0.5);
box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
backdrop-filter: blur(200px);

border-radius: 30px;
margin: auto;

padding: 21px 43px;
`

const TextWidthLine = styled(StyledContainer)`
    position: relative;
    display: inline-block;
    z-index:1;
    :after{
        content: '';
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 0%;

        height: 15px;
        width: 200%;
        background: linear-gradient(90deg, #191E92 0%, #6600CC 68.23%);
        transform: translate(-50%, -8px);
        

        // max-height: 500px;
        // min-height: 298px;
        // height: 200vh;
        // width: 15px;
        // background: linear-gradient(180deg, #6600CC 68.23%, #191E92 100%);
        // transform: translate(0, 46.7%) rotate(90deg);
    }
`

export default ({ ifMobile }) => {
    return <StyledContainer mt={'20px'}>
        {
            ifMobile
                ? (
                    <StyledContainer flex column alignItems='center'>
                         <TextWidthLine fontSize='40px' color='#fff' fontWeight='bold'>Token distribution</TextWidthLine>
                         <Pie ifMobile={ifMobile}></Pie>
                    </StyledContainer>
                )
                : (
                    <Box>
                        <TextWidthLine fontSize='40px' color='#fff' fontWeight='bold'>Token distribution</TextWidthLine>
                        <Pie></Pie>
                        <StyledContainer pl='22px' color='#fff' fontSize='18px'>IDO Vesting: 100% unlocked after Oct. 10th</StyledContainer>
                    </Box>
                )
        }

    </StyledContainer >
}