
const IFMAINNET = process.env.REACT_APP_ENV === 'MAIN'

export const baseChainID = IFMAINNET ? 43114 : 43113;
// export const baseChainID = 43114;
// console.log('baseChainID',process.env.REACT_APP_ENV,baseChainID)

export const HCT = IFMAINNET ?
  {
    address: '0x45C13620B55C35A5f539d26E88247011Eb10fDbd',
    symbol: 'HCT',
    decimals: 18,
    network:'main'
  } :{
    address: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
    symbol: 'HCT',
    decimals: 18,
    network:'testnet'
  }
  export const ClaimContractAddress = IFMAINNET ?'0xa2b7aadF03853217446ab8230b6a6c42ff640b2E':'0xE82e26218F7F0B480Bb84096DE2FBCa85AB2F8d8'