import React from 'react'
import styled from 'styled-components'
import StyledContainer from './Styled'

import chainlink from '../assets/images/investor/chainlink.svg'
import peckshield from '../assets/images/investor/peckshield.svg'
import wanchain from '../assets/images/investor/wanchain.svg'
import huobiVentures from '../assets/images/investor/huobiventures.svg'
import mexc from '../assets/images/investor/mexc.svg'
import zb from '../assets/images/investor/zb.svg'
import duck from '../assets/images/investor/duckstarter.svg'
import westarter from '../assets/images/investor/westarter.svg'
import kucoin from '../assets/images/investor/kucoin.svg'
import gate from '../assets/images/investor/gate.svg'
import kine from '../assets/images/investor/kine.svg'
import ld from '../assets/images/investor/ld.svg'
import aTCapital from '../assets/images/investor/capital.svg'
import redLine from '../assets/images/investor/redline.svg'
import spark from '../assets/images/investor/spark.svg'
import jrr from '../assets/images/investor/jrr.svg'
import kernel from '../assets/images/investor/kernel.svg'
import hive from '../assets/images/investor/bive.svg'
import daoWell from '../assets/images/investor/dao.svg'
import limeStone from '../assets/images/investor/limestone.svg'
import winkrypto from '../assets/images/investor/winerypto.svg'
import clockCapital from '../assets/images/investor/7ClockCapital.svg'
import avalaunche from '../assets/images/investor/avalaunche.svg'
import avatar from '../assets/images/investor/avatar.svg'
import avalaunche2 from '../assets/images/investor/avalaunche-2.svg'
import benqi from '../assets/images/investor/benqi.svg'
import avalaunch from '../assets/images/investor/avalaunch.svg'
import cmc from '../assets/images/investor/cmc.svg'
import blockBeats from '../assets/images/investor/blockBeats.svg'
import chainNews from '../assets/images/investor/chainNews.svg'
import duckDao from '../assets/images/investor/duckDao.svg'
import mirana from '../assets/images/investor/mirana.svg'
import primeblock from '../assets/images/investor/primeblock.svg'
import duckstart from '../assets/images/investor/duckstart.svg'
import { Image } from './Preset'
import useMobile from '../hooks/useMobile'

const BoxContainer = styled(StyledContainer)`
max-width: 1166px;
// width: 100%;
// height: 118px;
// background: #6425C5;
position: relative;
margin: auto;
`
const Link = styled.a`
    :hover {
        color:#6425C5;
        text-decoration:underline
    }
`

/* const investor = [
    {
        title: 'Partners',
        children: [
            {
                src: chainlink,
                url: 'https://chain.link/',
            },
            {
                src: peckshield,
                url: 'https://peckshield.cn/en',
            },
            {
                src: wanchain,
                url: 'https://www.wanchain.org/',
            }
        ]
    },
    {
        title: 'Exchanges',
        children: [
            {
                src: huobiVentures,
                url: 'https://www.huobi.com/',
            },
            {
                src: mexc,
                url: 'https://www.mexc.com/',
            },
            {
                src: zb,
                url: 'https://www.zb.com/',
            },
            {
                src: duck,
                url: 'https://duckstarter.io/',
            },
            {
                src: westarter,
                url: 'https://www.westarter.org/',
            },
            {
                src: kucoin,
                url: 'https://www.kucoin.com/',
            },
            {
                src: gate,
                url: 'https://www.gate.io/',
            },
            {
                src: kine,
                url: 'https://kine.io/',
            }
        ]
    },
    {
        title: 'Funds',
        children: [
            {
                src: ld,
                url: 'https://ldcap.com/',
            },
            {
                src: aTCapital,
                url: 'https://capitalant.com/',
            },
            {
                src: redLine,
                url: 'http://www.honglianziben.com',
            },
            {
                src: spark,
                url: 'https://sparkdigitalcapital.com/',
            },
            {
                src: jrr,
                url: 'https://jrr.group/',
            },
            {
                src: kernel,
                url: 'https://www.kernel-ventures.com/',
            },
            {
                src: hive,
                url: 'https://kine.io/',
            },
            {
                src: daoWell,
                url: '',
            },
            {
                src: limeStone,
                url: 'https://www.theblockbeats.com/',
            },
            {
                src: winkrypto,
                url: 'https://www.chainnews.com/',
            },
            {
                src: clockCapital,
                url: 'https://7oclockcapital.com/',
            }
        ]
    },
    {
        title: 'AVAX Ecosystem',
        children: [
            {
                src: avalaunche,
                url: '',
            },
            {
                src: avatar,
                url: 'https://www.avaxavatar.com/',
            },
            {
                src: avalaunche2,
                url: '',
            },
            {
                src: benqi,
                url: 'https://benqi.fi/',
            },
            {
                src: avalaunch,
                url: 'https://avalaunch.app/',
            }
        ]
    },
    {
        title: 'Media & Community',
        children: [
            {
                src: cmc,
                url: 'https://coinmarketcap.com/',
            },
            {
                src: blockBeats,
                url: 'https://www.theblockbeats.com/',
            },
            {
                src: chainNews,
                url: 'https://www.chainnews.com/',
            },
            {
                src: duckDao,
                url: 'https://duckdao.io/',
            },
            {
                src: clockCapital,
                url: 'https://7oclockcapital.com/',
            }
        ]
    }
] */
const ecosystom1 =
    [
        {
            src: avalaunche,
            url: 'https://www.avax.network/',
        },
        {
            src: avatar,
            url: 'https://www.avaxavatar.com/',
        },
        {
            src: avalaunch,
            url: 'https://avalaunch.app/',
        }
    ]

const ecosystom2 =
[

    {
        src: avalaunche2,
        url: 'https://twitter.com/Tederminant',
    },
    {
        src: benqi,
        url: 'https://benqi.fi/',
    },

]
const investor = [
            {
                src: huobiVentures,
                url: 'https://www.huobi.com/',
            },
            {
                src: kucoin,
                url: 'https://www.kucoin.com/',
            },
            {
                src: gate,
                url: 'https://www.gate.io/',
            },
            {
                src: mexc,
                url: 'https://www.mexc.com/',
            },
            {
                src: zb,
                url: 'https://www.zb.com/',
            },
            {
                src: aTCapital,
                url: 'https://capitalant.com/',
            },
            {
                src: ld,
                url: 'https://ldcap.com/',
            },
            {
                src: jrr,
                url: 'https://jrr.group/',
            },
            {
                src: spark,
                url: 'https://sparkdigitalcapital.com/',
            },
            {
                src: redLine,
                url: 'http://www.honglianziben.com',
            },
            {
                src: mirana,
                url: 'https://www.bybit.com/',
            },
            {
                src: primeblock,
                url: 'https://www.mexc.com/',
            },
            {
                src: kernel,
                url: 'https://www.kernel-ventures.com/',
            },
            {
                src: hive,
                url: 'https://kine.io/',
            },
            {
                src: limeStone,
                url: 'https://www.theblockbeats.com/',
            },
            {
                src: duckstart,
                url: 'https://duckstarter.io/',
            },
            {
                src: westarter,
                url: 'https://www.westarter.org/',
            },
            {
                src: winkrypto,
                url: 'https://www.chainnews.com/',
            },
            {
                src: clockCapital,
                url: 'https://7oclockcapital.com/',
            },
            {
                src: daoWell,
                url: '',
            },
            {
                src: chainlink,
                url: 'https://chain.link/',
            },
            {
                src: cmc,
                url: 'https://coinmarketcap.com/',
            },
            {
                src: blockBeats,
                url: 'https://www.theblockbeats.com/',
            },
            {
                src: chainNews,
                url: 'https://www.chainnews.com/',
            },
            {
                src: duckDao,
                url: 'https://duckdao.io/',
            }
        
    
] 

export default () => {
    const ifMobile = useMobile()

    return (
        <StyledContainer >
            <BoxContainer width={ifMobile ? 'calc(100% - 40px)' : '100%'} mb={ifMobile ? '61px' : '104px'} height={ifMobile ? '82px' : '118px'} mr={ifMobile ? 'auto' : '0'} ml={ifMobile ? 'auto' : '100px'} flex alignItems='center' justifyContent='center' color='#fff' fontWeight='bold' fontSize={ifMobile ? '25px' : '32px'}>
                <StyledContainer position='relative' zIndex='3'>
                    AVAX Eco Strategic Partners
                </StyledContainer>
            </BoxContainer>
            <StyledContainer m={ifMobile ? 'auto' : '0px -65px 0px 65px' } maxWidth={ifMobile ? '200px' : 'calc(100vw - 200px)'} width='100%' flex alignItems='center' justifyContent='center'  wrap >
                {ecosystom1.map(i => ( 
                            <StyledContainer key={i.url} m={ifMobile ? '10px' : '10px 65px'} >
                                <a href={i.url} target="_blank">
                                    <Image src={i.src}></Image>
                                </a>
                            </StyledContainer>
                        )
                    )

                }
            </StyledContainer>
            <StyledContainer m={ifMobile ? 'auto' : '0px -65px 0px 65px' } maxWidth={ifMobile ? '320px' : 'calc(100vw - 200px)'} width='100%' flex alignItems='center' justifyContent='center'  wrap >
                {ecosystom2.map(i => ( 
                            <StyledContainer key={i.url} m={ifMobile ? '10px' : '10px 65px'} >
                                <a href={i.url} target="_blank">
                                    <Image src={i.src}></Image>
                                </a>
                            </StyledContainer>
                        )
                    )

                }
            </StyledContainer>




            <BoxContainer width={ifMobile ? 'calc(100% - 40px)' : '100%'} mb={ifMobile ? '61px' : '104px'} height={ifMobile ? '82px' : '118px'} mr={ifMobile ? 'auto' : '0'} ml={ifMobile ? 'auto' : '100px'} flex alignItems='center' justifyContent='center' color='#fff' fontWeight='bold' fontSize={ifMobile ? '25px' : '32px'}>
                <StyledContainer position='relative' zIndex='3'>
                    Investors & Partners
                </StyledContainer>
            </BoxContainer>
            <StyledContainer width='100vw' maxWidth='1166px' m='36px auto 0' flex column>
                {   // 分行版本
                   /* investor.map(i => (<StyledContainer flex key={i.title} wrap justifyContent='center'>
                        
                        <StyledContainer m='auto' maxWidth={ifMobile ? '320px' : '813px'} width='100%' flex alignItems='center' wrap >
                            {i.children.map(j => (
                                <StyledContainer key={j.url} m='10px'>
                                    <a href={j.url} target="_blank">
                                        <Image src={j.src}></Image>
                                    </a>
                                </StyledContainer>
                            ))}
                        </StyledContainer>
                    </StyledContainer>)) */
                <StyledContainer m={'auto'} maxWidth={ifMobile ? '320px' : 'calc(100vw - 200px)'} width='100%' flex alignItems='center' justifyContent='center' wrap >
                {investor.map(i => ( 
                            <StyledContainer key={i.url} m={ifMobile ? '0' : '10px 40px'}>
                                <a href={i.url} target="_blank">
                                    <Image src={i.src}></Image>
                                </a>
                            </StyledContainer>
                        )
                    )

                }
                </StyledContainer>
        }
         </StyledContainer>
                <StyledContainer  m={ifMobile ? 'auto':'80px 0 0 0'}  maxWidth={ifMobile ? '320px' : ' null'} width='100%' flex alignItems='center' justifyContent={ifMobile ? 'center':'space-around' } wrap >
                    <StyledContainer width = {ifMobile ? '100%' : '520px'}  fontFamily='GTText' fontSize='14px' lineHeight='18px' color='rgba(255, 255, 255, 0.25)' fontWeight='normal' fontStyle='normal' letterSpacing='0.5px' textAlign='justify'>
                        As industry's leading blockchain security company, 
                    PeckShield attracted widespread attention from the industry since they have discovered issues such as Ethereum smart contract BatchOverflow loophole,
                     been included in the Etherscan.io recommended vendor list for smart contract security audit,
                    and ranked Top 3 worldwide in the Ethereum Bounty Program.
                    </StyledContainer>
                    <StyledContainer mt={ifMobile ? '20px' : ' null'} textAlign='right' >
                        <Link href={'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-HurricaneSwap-v1.0.pdf'} target="_blank" >
                            <Image src={peckshield} ></Image>
                            <span >See Audit Report</span>
                        </Link>
                    </StyledContainer>
                </StyledContainer>

        </StyledContainer>
    )
}