import styled from "styled-components";
import StyledContainer from "./Styled";

export const StyledButton = styled(StyledContainer)`
    color: #fff;
    // padding: 13px 40px;
    border-radius: 100px;  
    background: linear-gradient(87.64deg, #6600CC 0%, #3E7BFA 100%), rgba(255, 255, 255, 0.5);
    margin-top:18px;
    display:flex;
    align-items:center;  
    justify-content:center;
    font-size:28px;  
    height:64px;
`
export const StyledDisabledButton = styled(StyledButton)`
    color: #fff;
    background: #C4C4C4;    
    border-radius: 100px;
`
export const StyledButtonInner = styled(StyledContainer)`
  background: linear-gradient(87.64deg, #600CC 0%, #3E7BFA 100%), rgba(255, 255, 255, 0.5);
`
export const Image = styled.img`
    display: inline-block;
    width: 100%;
    object-fit: contain;
`

export const ImageLine = styled.img`
    display: inline-block;
    object-fit: contain;
    margin-left:5px
    
`

