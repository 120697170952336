import React from 'react'
import styled from 'styled-components'
import StyledContainer from './Styled'
import mail from '../assets/images/fi_mail.svg'
import { Image } from './Preset'

const FAQContainer = styled.div`
    z-index: ${({show}) => show ? '20':'-20' };
    color:#fff;
    ol{
      padding: 0;
      margin-left: 20px;
      font-size:12px;
      p{
        padding:0;
      }
    }
`

const Text = styled.div`
    font-family: GTText;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color:#fff;
    margin-top:10px
`

const Container1 = styled.div`
    margin-bottom: ${({ifMobile}) => ifMobile ? '0px':'0px' };
    margin-top: ${({ifMobile}) => ifMobile ? '0px':'0px' };
    position : ${({ifMobile}) => ifMobile ?'relative':'absolute' };
    left : ${({ifMobile}) => ifMobile ? 'null':'720px' };
    padding: ${({ifMobile}) => ifMobile ? '0 40px':'0 0px' };
    width: ${({ifMobile}) => ifMobile ? 'null':'320px' };
    top: ${({ifMobile}) => ifMobile ? 'null':'280px' };
    

`
const AllocationContainer1 = styled.div`
    color:#fff;
    margin-bottom: ${({ifMobile}) => ifMobile ? '20px':'0px' };
    margin-top: ${({ifMobile}) => ifMobile ? '0px':'0px' };
    position : ${({ifMobile}) => ifMobile ?'relative':'absolute' };
    left : ${({ifMobile}) => ifMobile ? 'null':'720px' };
    padding: ${({ifMobile}) => ifMobile ? '0 20px':'0 0px' };
    width: ${({ifMobile}) => ifMobile ? 'null':'320px' };
    top: ${({ifMobile}) => ifMobile ? 'null':'20px' };
`

const Container = styled.div`

`
const AllocationContainer = styled.div`
    color:#fff;
`
const TotalSpan = styled.span`
  margin-left:10px;
  font-size:24px;
`
export default ({ show , ifMobile }) => {
    return (
        <Container  ifMobile={ifMobile} className="side-block">
        <FAQContainer>
            <StyledContainer fontSize="16px">FAQ: </StyledContainer>
            <ol>
              <li>
              <p>Where could I find the winner list?</p>
              <p>You can check the winner list in this Medium: <a style={{color:"#466ef4"}} href="https://hurricaneswap.medium.com/winner-list-of-hurricane-effect-program-a5c10747b969" target="_blank">Winner List of Hurricane Effect Program</a></p>
            </li>
            </ol>
        </FAQContainer>
        <div style={{display:'flex', flexDirection:'column',justifyContent:'flex-start' ,width:'100%'}}>
            <Text>Any question?</Text>
            <Text style={{marginTop:'5px'}}>
                Contact us  
                <a href='mailto:ido@hurricaneswap.com' target="_blank" style={{marginLeft:'10px'}}>
                    <StyledContainer width='12px' height='15px' mr='5px' flex row alignItems='center' >
                        <Image src={mail}></Image>
                    <Text style={{display:'inline-block',marginTop:'0px',marginLeft:'5px'}}>contact@hurricaneswap.com</Text>
                    </StyledContainer>
                </a>
            </Text>
        </div>

        </Container >
    )
}
export function Allocation({reward,ifMobile}){
  return(
    <AllocationContainer ifMobile={ifMobile} className="side-block">
      <StyledContainer fontSize="24px">Hurricane Effect Program Reward</StyledContainer>
      <StyledContainer mt="20px" fontSize="16px">Your allocation</StyledContainer>
      <StyledContainer  mt="6px" fontSize="24px">{reward ? `${reward} HCT`  : `---`}</StyledContainer>
    </AllocationContainer>
  )
}


// export function Allocation({testnetReward,bugBounty,total,ifMobile}){
//   return(
//     <AllocationContainer ifMobile={ifMobile} className="side-block">
//       <StyledContainer fontSize="24px">Your allocation</StyledContainer>
//       <StyledContainer mt="20px" fontSize="16px">HurricaneSwap Testnet Reward Program</StyledContainer>
//       <StyledContainer  mt="6px" fontSize="24px">{testnetReward ? `${testnetReward} HCT`  : `---`}</StyledContainer>
//       <StyledContainer mt="20px" fontSize="16px">Bug bounty</StyledContainer>
//       <StyledContainer mt="6px" fontSize="24px">{bugBounty ? `${bugBounty} HCT`  : `---`}</StyledContainer>
//       <StyledContainer mt="20px" fontSize="16px">Total allocation <TotalSpan >{total ? `${total}`  : 0} HCT</TotalSpan></StyledContainer>
      
//     </AllocationContainer>
//   )
// }

