  const wallet=  { "0x5F62611ac71a9f10e5dBF6f6981875FC8e5581B4": " 1666.66 " ,
     "0x127108AbEea6d4C23c9843B97FADf3c99752D075": " 1666.66 " ,
     "0x48AD54BA2e36474Db632BE4AE84344274d8D6c50": " 1666.66 " ,
     "0x2cEb68aCb009F47723c3c62C0E07105441e83643": " 200 " ,
     "0xeE433f2F71F1152AC3342e029a980ACF730F831C": " 200 " ,
     "0xdc8D2Da23af0b418ed18101071eD16b223AF546e": " 200 " ,
     "0x3aE52eF45F349a28404A4a8CFed323e3bA187099": " 200 " ,
     "0x975474ff64739316c3a4e8e66c791ce83ca02f38": " 200 " ,
     "0xC5eA1AE951ADaF239ea7B687aDa35222BaaEa751": " 200 " ,
     "0x500ce5b115602a1dc9c6a9ad6ea80ca07ae91058": " 200 " ,
     "0x926F069A76c863dE736002Aa3B485686269BE5f2": " 200 " ,
     "0xaf18043bF7458A4115b427d1eCcbf533638DEd42": " 200 " ,
     "0xf60B12aD0d7CD84212d961cDDfF398f679cB3d94": " 200 " ,
     "0x0612Bd2F6186F2f1C7fe3A9aeF869ecB9A3b71D3": " 200 " ,
     "0x10b2a5129C54DECE4D3Da91d48933Cd70E296690": " 200 " ,
     "0x2FC3Fd7c06aDfc48736122ea33820038CC16DcDb": " 200 " ,
     "0x0a2278692568548A46DaD23705F41fBA42E64bE8": " 200 " ,
     "0x80813390B3e1b380173062E8d7e7C4801e804C87": " 200 " ,
     "0x58552189AC1bf4720fAa6d9AdC5C2e5B650Cb962": " 200 " ,
     "0x9271A457e47B3C7C5a600Ee344c5B84F2478119e": " 200 " ,
     "0x62adE07EbC13A53D58abE68e99fC9B48Ca69E8AC": " 200 " ,
     "0xaFf9CB8F821B077Bb1Aa0b29433df7816eA4C2fB": " 200 " ,
     "0x9D564ED34217a8aa114327B39887fc3fAb55dFa3": " 200 " ,
     "0xA2745bBF0790494a08C8FFCc295065cAe19D4E5f": " 200 " ,
     "0x77850cf82e865aa3158673267e2a21d86c3f4ddc": " 200 " ,
     "0x03905013886600eb1E2538eb7175f1d01c42D61E": " 200 " ,
     "0x160114065031e81e5f2c2586db3303dea4adacd1": " 200 " ,
     "0x88ece27519DF392126825D486DB7f63082919b8f": " 200 " ,
     "0xB6A9163D12F3854e913A43CA6eE28cC87Aa7Ef3d": " 200 " ,
     "0xBbF09c6a41dA32AE6d12cbc46Fa18c8dE1218c34": " 200 " ,
     "0xbF2E1D0031cD0D43cDDb61e4a5876B737C4dF174": " 200 " ,
     "0x8d0A8F5f45488ad79eD98fD8F473efCcd1d97b01": " 200 " ,
     "0xc5469d6E33E72890F51AbC29946101452e38B575": " 200 " ,
     "0x7B9044D2862E1bcf5212F9d41583c291CD5419f6": " 200 " ,
     "0xAb931a7bA67908eFc707e2c2B82eef3f64ef9FAe": " 200 " ,
     "0x0CEAd33EB6AdF4860B6b8F66687f51567E111973": " 200 " ,
     "0x9830f34E1dE4fAce59cdC777e1def43DF83397dd": " 200 " ,
     "0x10d7d7De4259041846E9b64B6958b478A0d5Bac4": " 200 " ,
     "0x9e1c63A25Cff13F2068477D5e23dAB17fb22616A": " 200 " ,
     "0x2369162D6f687fa2c5bC34642aCE1E280E2b5934": " 200 " ,
     "0xB63a5C6E30195Ee09EB15F76C75EdE285F74edDd": " 200 " ,
     "0xD85eCdFBD0aD8da96CCd67f66607240056eBAae6": " 200 " ,
     "0xab2f39C88b9D1BaA9d59FEaCA08dF52869825105": " 200 " ,
     "0x9e45fa3c9396772f60c0cf79d6014d080eed406e": " 200 " ,
     "0x76834559a47c1ad6848E124004e89F9d74930249": " 200 " ,
     "0xb107b4ed59641DFBCd61a67f0F998d11686D6f07": " 200 " ,
     "0xAEb9A4587aC3f43c7f96412e08ccd62e32Ed27Fc": " 200 " ,
     "0xfd86d28a6df8635c47af7c6ac5597893070d6336": " 200 " ,
     "0x3f2d8ce9ade28351b1642b2f17e71491a7f4d5dd": " 200 " ,
     "0xC21A5ee89D306353e065a6dd5779470DE395DBaC": " 200 " ,
     "0x18a226ca657bad6c44c40739005b1ce3f5f69a61": " 200 " ,
     "0x82c854504F266dAd4a7eA9E448Ca7B1572AebF1C": " 200 " ,
     "0xA877d2c6C5a6717c4b15F3EAFA0A38cE16c5f716": " 200 " ,
     "0x661352FaBA933120eA099FCD3D2F4Ebc23704001": " 200 " ,
     "0xDc25d6432eE66D8Ca22D6849A3DC53D51636b75f": " 200 " ,
     "0xE7AB2E7Eb5A13fFc4E6FFcC8bc84Ee820448De61": " 200 " ,
     "0x6FF9D941EFD0CEF08499bC2D4085CC4Fb61038C8": " 200 " ,
     "0x3c8b659caB32B698973E1b01B800d1dECc39aBb5": " 200 " ,
     "0x2409656BFb9e20d4A2840E6C8b51415554995D79": " 200 " ,
     "0xb69ffaa60179f04966112e9d1ae96dcbdd97829c": " 200 " ,
     "0x908ec8e00ac68fa5051994d8f2092d1418b296f5": " 200 " ,
     "0x9a29151f8bed475a2f36233975c41e90fb82a93a": " 200 " ,
     "0x1984ea5827094ba26751b1c81802563f8073af08": " 200 " ,
     "0xc4f2bb262653d23f7260942502aa4b204d2edffb": " 200 " ,
     "0x343694a48355280614148A2024766a229734FBf8": " 200 " ,
     "0x86a9bc2d29aa578333f8417ce3202175d83b3ce5": " 200 " ,
     "0xbd0841a19c6e28f14fc9cf5f75ee3d745859ef92": " 200 " ,
     "0x8bd4d00a9f459d38c672043c6832ca311177bdab": " 200 " ,
     "0xddcc34716c8290351a94f35aee9b106ea9f2fc77": " 200 " ,
     "0x45c5bf04d51ad38b53ff05a12019c3075e42b47a": " 200 " ,
     "0xc3a28DcAfCE6915F40AF4b91C05b3814BcB4277B": " 200 " ,
     "0x291d75BBF8BC53f3547AD83c61fcEC2C2881ba30": " 200 " ,
     "0xAD501BA68f84C368E7f7dA4FA0d7ca5b5450084b": " 200 " ,
     "0xc1960d859AD9bf5951c7d5017Ee64725c93bA0EB": " 200 " ,
     "0x18F30B493f039b4009D250F5a8314722588764DA": " 200 " ,
     "0x6811F4fE6cf28EE01c8bF6D7b2F455f84F392149": " 200 " ,
     "0xf0ab648Ff3c31491aa381802f904Ab36eD771592": " 200 " ,
     "0x10be9EeC0eB8BDdE4623546ce5416f94A0Fc319A": " 200 " ,
     "0x7371143753Afa841c44901C869178aeD81107cA2": " 200 " ,
     "0xCca9C9424ce8C4b2aADe26eCC27d8B5A3cbEE7A8": " 200 " ,
     "0x4c710A4910347C2e1b9111834dd7ad673CFFe3a4": " 200 " ,
     "0x61F269B0526911351D708b45552A9094AAF355c9": " 200 " ,
     "0xD788D5e67DE9E122FC1bd0895FBA79877317197A": " 200 " ,
     "0x66EDe4F4220453459e2d136259b2152aAA4CD807": " 200 " ,
     "0xbF5F8e6f4875Dc790f6d883848EE0D1A5B02202D": " 200 " ,
     "0x63229ac10992F95827cb50abC61fA52086EeF0e1": " 200 " ,
     "0x350e30a1d9B880e6b71e0f8177c69CC03E5689Cb": " 200 " ,
     "0x01f89d671F1ee53D8D3E83c167aB8ffA316Bf60A": " 200 " ,
     "0x7537A4bbe1e7a52Ab23feA129dda1AcC628e7cB5": " 200 " ,
     "0x4094cAC7953389D9589E82Db31197aE0CEF2B6ea": " 200 " ,
     "0x44d9656Cf7981B2401AB4416f1bC4a93E948375C": " 200 " ,
     "0xd3D80413b2f5863986a43733a8a65caa863F2cfF": " 200 " ,
     "0xBD3ed7668b840c8Bb30e000EC2b681D0B40D7399": " 200 " ,
     "0xbA84CA12655F8FCB0C06a4dD8B7E741fCb50a403": " 200 " ,
     "0xc1DddA938EDf99C2b5F1247cE216D97F893A8920": " 200 " ,
     "0xDFA2c6d8a95dCD998cDC81477DB190fB8fc4396F": " 200 " ,
     "0xeC254F713ad49fee042F89606cfdB661D270c19b": " 200 " ,
     "0xD4543e9efef44653f5907f925eC3A175faDd15fB": " 200 " ,
     "0x0e0dF8a3c168181410615b632e0cF29E5d148996": " 200 " ,
     "0xC22e2Cb9aE58feA6568f3284793e5CEc678DFD9B": " 200 " ,
     "0xA70771d7C10aC346681F27C8Dd1B6d300e2410Bc": " 200 " ,
     "0x18db24073154049e3c71a470ab6ec4dd30f02f2d": " 200 " ,
     "0x4f2acfcac9899e01436cea3c78b3c90842dc641b": " 200 " ,
     "0xba96aca618858B701659379949fD72a2C7C33815": " 200 " ,
     "0x33C36327C45AD6E03ab697F57b412C5828AB59D0": " 200 " ,
     "0xF434B410Ff18a1eCD7c070AC90BBc3E72AE72d35": " 200 " ,
     "0x3D6db46E3A98F1D996aeAc9bbfBa570D0a60b4F7": " 200 " ,
     "0x7ddfe815806b26915882799e00517c803e71eabb": " 200 " ,
     "0xf2B519678CA66bdF27257bE0cacC57B8ea31E429": " 200 " ,
     "0x18972e154fc853214D757a585426cf6F764c4a56": " 200 " ,
     "0x61634954bf037BE38352a65bb7E916CbcC63D365": " 200 " ,
     "0x1A0EA97C81FFE408eE7EA3d687Edad7b13B037FE": " 200 " ,
     "0x7f085B3a67DE7c6c5f717E51297A9d8a65e656C9": " 200 " ,
     "0x1Bde93ABf22e54EB9Aead955Ee31500eDade1469": " 200 " ,
     "0x0e11463a8752c0667B8AD18f4198B857e614cea2": " 200 " ,
     "0x3b9A27C646F951281c9834Ba9962E784bca87EFb": " 200 " ,
     "0x5fe47716773b794450911F61661A63A9979DBA31": " 200 " ,
     "0x5F4C15f4aE77d08454B494eD0909879398A43D9f": " 200 " ,
     "0xd8529e92ba9AF1857765f5f8EB9709529D83e9eB": " 200 " ,
     "0xA4AF4ce4190f3919317671C7251B767dD37d52DD": " 200 " ,
     "0x179FFc8284Dc637CDd158c54f7817a2f4281A274": " 200 " ,
     "0x87FfA03AdEbddEF20D616c5D4499F0FaBA2111F2": " 200 " ,
     "0x84a12Bb4684AE5e5C6B4A330F62991579A5f9360": " 200 " ,
     "0x38B8d53789891EE81E20Cd382407A2cA614bd61a": " 200 " ,
     "0xb07D94d55621EC5cF76eF974f3DD094C6Dd1995E": " 200 " ,
     "0x79Cf248ce56690A7cE9e650D24856f612CCD5070": " 200 " ,
     "0x5655D3986E2B95CbD95756b4B8C103FbC0789839": " 200 " ,
     "0x079e6db7CC863534AE9c748FCC8672FC65feA03E": " 200 " ,
     "0xC6e92D38c59B6EC7369C147Def89fBC5DDA9b900": " 200 " ,
     "0x6638b7b9471f04Ea0DA2EeD99A9cA22a1Eb17518": " 200 " ,
     "0x3899b747D58a5c6F5b953dfE3226b0B8964f9561": " 200 " ,
     "0x22E138AeEAc6eFAfA255bf589bb8d06a567b2e1E": " 200 " ,
     "0x9dB45cDc2FAe574e4899e5eFE8DA06dcc3daF931": " 200 " ,
     "0xbB7a7d37384bF9df08B96e1bD3B296d81E0585E0": " 200 " ,
     "0x2d14A7A535055539307f0A0FE323D71B89E219E7": " 200 " ,
     "0xc29dD8CD83Bf33E61B8Ad4DcB1E8aEB19B8cb2E3": " 200 " ,
     "0x508542d14e8Ff3f8f3D5335aB7e825e51D85084A": " 200 " ,
     "0x46602fD2B87A8f5dAc28CF6A5793cB270b17ED08": " 200 " ,
     "0x7F901D0C5e549FbaD4284185A1aDc4bA8Babf3C4": " 200 " ,
     "0x5207E9D9d1a14839731b06869f98B088e0B8c313": " 200 " ,
     "0x9ae9E1e1FDC5f7b9d3a6fC69E46A0e07CDE9C2F6": " 200 " ,
     "0x35b2F79f1e20C847Df1BCedf7bce4b000Fe42478": " 200 " ,
     "0x85095A77E912657CC5B3Fd8D57b6D153C096f804": " 200 " ,
     "0xdef7fac49ffd3cb5875563b6e5a26e3d7f541c84": " 200 " ,
     "0xbfF83A212c418E48a74789AB62719c73Db743ef5": " 200 " ,
     "0x92b1D7c26Da469D2a78E557c3d0727CE971dF772": " 200 " ,
     "0xb93F36D8Bd1c60bD6b3796B6D0417b98637712fC": " 200 " ,
     "0x49F477AfbdA49D47e1955554C81FD5806Ac2de51": " 200 " ,
     "0x12782c45D788256D4292BC14F5964DB1ea2f1b22": " 200 " ,
     "0x7E5D010178ec1F30d4E3B851187Ff636FE4f1798": " 200 " ,
     "0x537c3aD2d3f07fBeF935131d175e1EE6011dDf99": " 200 " ,
     "0x6BD1d1DCc034d9A6C9E9aFaC2B849c1939920025": " 200 " ,
     "0xc31339663612791fc231C1628Ec3a7cf4d51285F": " 200 " ,
     "0x529B66A7f3a7D30E93dC0389b0cc0F9472E77a72": " 200 " ,
     "0xeb1b43f6838acFCCE96502725B1DDd8d6f2DC6B8": " 200 " ,
     "0x3e90cFa9c977DE32c66A1679278Ac42602c603eE": " 200 " ,
     "0x041525aE04c25882070afEf4919BdD53b0aa6c9B": " 200 " ,
     "0x77d58F555C9923a2caD1186d6F61832Ba23dcba2": " 200 " ,
     "0xeA6d5a9C6B1C285480FF903b0A76782E81ccf536": " 200 " ,
     "0x07925C6B95Ee613672d5f58e88a7EFf0442b7347": " 200 " ,
     "0x2e239bCD510764902276077116a87080AA397658": " 200 " ,
     "0x7a78ea70c11b2b5B4a423BF1532FE0D46437Ec2D": " 200 " ,
     "0x99626CB6D5b935bE696ffA5168846143ACf8b8D9": " 200 " ,
     "0x79036C1C3322A4576b3FCdd8C0d34d00bd26965f": " 200 " ,
     "0x80cCC5Ca76785fc898e252AB27DC28b6981A97Da": " 200 " ,
     "0xb829115C82C26738a0AD54692bE06a204FcB311C": " 200 " ,
     "0x82d66dABB62C118a33a64E75946434d189F7AD58": " 200 " ,
     "0x6FD8862972Cd83dc991fA228Aa60659590a42473": " 200 " ,
     "0x36d7966Ee8Fe554baE2de6df7f262263414f8a91": " 200 " ,
     "0xD1ca84FD3426a61aC2a3d2A926C77c775021315C": " 200 " ,
     "0x7Bf7Fb077adb485Ac0B80499d633FF415DF0f95b": " 200 " ,
     "0xE6EE023F975b7d0a49a6F4dC67b8b58Acd2075C8": " 200 " ,
     "0x24954Ce4BbD0DBFAa0021170EF6a9C9ab511a19B": " 200 " ,
     "0xcb8d581FD8770406DcB53BA9A081a92fD10B73Aa": " 200 " ,
     "0xd3Ace9DdF3a62F9b8F690c0b1D52c1Bc2AE79695": " 200 " ,
     "0x5B6A23191Dd8F311a030793E109c764f53299D1D": " 200 " ,
     "0x5Fcb26D0274D5b29E39B8Bd1FaFf90340A90Ba56": " 200 " ,
     "0xe4E38F923143175190115547fC04F16CB29F480e": " 200 " ,
     "0xa4b23C02604Fd3b748E26C0A112Dc5221461930f": " 200 " ,
     "0xf666263C420548b29160ff1FE255579DEe23274A": " 200 " ,
     "0x1290A64f2156a6075F6244d0c2d48c108521a492": " 200 " ,
     "0xd92b5bd0048dc13469FBE17EdE75Aa5AA91f9296": " 200 " ,
     "0xe79A7A3C87A51421897A9F0FE17846BeDf94AE25": " 200 " ,
     "0xaB560C3a11Afc6c80Ac733574d43911e8dEa9BF5": " 200 " ,
     "0xC6eFF3E2C01750C1bd30e51B862ECa0dBeE29185": " 200 " ,
     "0xb7b03a7301De7f73177551Ae548046D92a1797ED": " 200 " ,
     "0x76c990543C682306bE06015AEcF2fE5ea04854c2": " 200 " ,
     "0xF5e0DbE9159E3Be204b50E92aDAC78Fed30473D8": " 200 " ,
     "0x9948529Ad6e2698A20A7FE6411aDF043E4BdafAd": " 200 " ,
     "0x7917187a0CABe2464309B497613f9f15Ce7E3F17": " 200 " ,
     "0xE228f277071A185E260CF2DcD976bF7CCf0B94F7": " 200 " ,
     "0x401DFf7F3530F4F55913dbe417499baD80B68A4E": " 200 " ,
     "0x3cafd4134dF4ff8580F345e35d7E3ae705a2308f": " 200 " ,
     "0xC99a75F23C50eAAB2595FF19Aa19caAB94a2659e": " 200 " ,
     "0x5EB1Ed2f5723A09111151E6A51b3d266Bd135f76": " 200 " ,
     "0xB7C14802A2071488DBa7989b0d2170f6cBDC2646": " 200 " ,
     "0x60F87b8C0F860F5F8FA6c355d70ae3bed8C313aC": " 200 " ,
     "0xfD0D7cC9210b088800e804EDFA236078e087337f": " 200 " ,
     "0xb0C2eC1eA4d82800bc196FBAf67b48801bb1516C": " 200 " ,
     "0xDB5D8A2cbb61517eE290Fb1F8f2Cd1Ca79C8871e": " 200 " ,
     "0xb0D7009e7f5787Fc243FCF49C9E440AC244D5a81": " 200 " ,
     "0x8432C632c08842E736F1505C1946218531Fcd208": " 200 " ,
     "0x6aF33297dd2bF9b8568d7d5b1cAac9832b278227": " 200 " ,
     "0x61403954E13345B8730f040356742B036801f527": " 200 " ,
     "0xc4B8F9150525a0bfc841447F2c55647759a59e31": " 200 " ,
     "0x69E63a5935F650EA13e4F62075e4ab6d5ae768Eb": " 200 " ,
     "0x1381D9436a5f66744666c71aA428Aa374689D098": " 200 " ,
     "0x2c5bcd8ee431eb81637cfda0860f367c500424a2": " 200 " ,
     "0x8739148337bc5F79b82011467AAAACb331E6AB54": " 200 " ,
     "0xDd00F9bcc979Cfee1bF1DF3a2c3141F99a7bfC8C": " 200 " ,
     "0xE1c35Dd20897E86734166C6c5404dE3465373443": " 200 " ,
     "0xBe5C4c51cdAdead26845e185315AD88E508881b4": " 200 " ,
     "0x4c3C8Db4434D261BB78394414874895b2f81BED6": " 200 " ,
     "0x769917905801eE8b309E6aB8218813786dC2f488": " 200 " ,
     "0xED752C690d2426ED66Bd696500056ba60a79D7A0": " 200 " ,
     "0x5d3Fa700e61C36a46Efa084c289aeeaF1D54Dba9": " 200 " ,
     "0x2aFD25666EAdAAAd70bc33F047B37de07D230959": " 200 " ,
     "0x79ECc825b264b5cC4e85dF870797598C9E60EF26": " 200 " ,
     "0xEF5Adf1490f582Ae9E5188B4dc82294FD677baAb": " 200 " ,
     "0x0406EfB03027DD25bE4858d8D0e03aD89ff71Ef9": " 200 " ,
     "0xf13596625b706C59C3b6433d57aabfF416Dd2CA9": " 200 " ,
     "0xD103Ea0a5AdD57306303D39F5151684b9a9C439A": " 200 " ,
     "0x71998Ddd975179eF6fD49EFBD864bF7C0988fddc": " 200 " ,
     "0xa2448bc63F328647ECe6D55269101f0968De43c8": " 200 " ,
     "0xfffC7CaE6Dc4bE7a3E1c6F5c2c7161ee2D926b31": " 200 " ,
     "0x6602Ded37da33C07C258A69e9A22932a809cadAA": " 200 " ,
     "0xAcA8BabE2C329EB618E6e58648d9426978a44625": " 200 " ,
     "0xF41e615640D59aFf256ADc525fB9b4824f85Fcc5": " 200 " ,
     "0x6e31AaaD66705ca1ff8fF9683a4318dE1061692e": " 200 " ,
     "0x134E851036e51730E6817956C982b52Df5333aEC": " 200 " ,
     "0xa47187BEBD622382d19eb9a6f275223bffC40149": " 200 " ,
     "0x88847974Cb9cfB441BC586EC196259C27E4950DF": " 200 " ,
     "0xCB8874F04040D9Ab28d2BE19B8E5e692c7755751": " 200 " ,
     "0x8171CfBeb8E66A0F9d6036Ec2D0807DF544912E3": " 200 " ,
     "0xb30A432a54a756Ed7F470F8435328F254d422D85": " 200 " ,
     "0x286349b34f03Fdc15C5F4518d09aD9527dC1EDf7": " 200 " ,
     "0x651D32209f5D4d29C123b8B9e2c3B409f1db261F": " 200 " ,
     "0x99995825c251F3fD593B935a8c5bA7Ee0Ed18888": " 200 " ,
     "0x954F1D97aE9c4a27d012e70cCaAfde29822B3F2B": " 200 " ,
     "0x0fF2363260556eE9f2c004F6418A55da9FA900Bc": " 200 " ,
     "0x5b3f7eb23bc0bcc396ceb800179d7507a2d3b98c": " 200 " ,
     "0xB5d65a52d8C7Fec5E628482Dd2fd08b50D2F6a29": " 200 " ,
     "0x9604ece2239c9483f9a9b01cd0fc2468d26463eb": " 200 " ,
     "0x3215bb6813ecd6fdff10b7894eebae5a398394bb": " 200 " ,
     "0xc0d848a7cac0d1a95a1fe734a3e512de6eaa84d4": " 200 " ,
     "0x2d673f0013c84fc644b42c212bf761818b70fefd": " 200 " ,
     "0x0000e9a84df8c2963a9e4210df4d84d5d6836666": " 200 " ,
     "0x0dc7e345747983207b0890ce1b7a58effbd8b722": " 200 " ,
     "0xDF16F5853C75e29348A3574adce1De5A8bC4DCb1": " 200 " ,
     "0x03749373486ab001bD6191a8ca2f663ec738A9F9": " 200 " ,
     "0xEb4f2c129b03f115092aB6F2ae408a862121DcfE": " 200 " ,
     "0x2C09085E71F37CA59f064d8bFd11Ec26be2b36ff": " 200 " ,
     "0x1297CE7f53021D75CE7541e525AeaDaaa14950f1": " 200 " ,
     "0x6e9317f46a66e8ab27be4f4e917978977784cb26": " 200 " ,
     "0x16CcAe539Ea0A187d9Ec42640aFa5f38aCbf1BBB": " 200 " ,
     "0x66555284bdf2abf0017594de5cbd92beec7912aa": " 200 " ,
     "0xda143Aec7eE05E9d80a6Ada8EC5AE82B32543240": " 200 " ,
     "0x6608F5de70124a5B94f158AfdbE0663cE33A52a0": " 200 " ,
     "0x2b2a5827a2dc05b9481c31c8e3830045acc0fe08": " 200 " ,
     "0x378aab7790c0fa504b0a182d0b78bb0dd3765ec5": " 200 " ,
     "0x622dc61ed1ce5ee24770e7f671760f72fc3dde71": " 200 " ,
     "0xaef3f2c209ea84b86c3712be5907d6fa5a52ce93": " 200 " ,
     "0xcb42a41f717547091686ca1150e83d460b2b89f8": " 200 " ,
     "0x8b6c008fa7071f6e01036f43e7f5e5321b2dfa2a": " 200 " ,
     "0x4838F0E6E93dFD14F8c25A49656457Af208a225c": " 200 " ,
     "0xfc68a45459ebbedaabb65e2338b0c01a3967920c": " 200 " ,
     "0x28E1F018101f5fFF693ffCcD68507841ED5329Aa": " 200 " ,
     "0xc03Edbf9B3FC06Fc48dC482BdABd4282f70fD2Cf": " 200 " ,
     "0x2345B0e6448496AFd1FDB8e90fbac5995E2690D6": " 200 " ,
     "0x4953B8A26E5fc65d45925710f6992521c9D9F7fb": " 200 " ,
     "0x7F3Ad305d730f82e78C8D4256C1776408b5Ee840": " 200 " ,
     "0x8507e785f987e1142cb780e5Dd8F5E134168FDfA": " 200 " ,
     "0xcC8d21F74B1c0127fFe707C316ef1304BaF9d329": " 200 " ,
     "0x3F3f4e8738313D996a735b91419940DaaEA0C4C7": " 200 " ,
     "0x93F0a01b7A8884BC929faaDF6509aA74bB841651": " 200 " ,
     "0x5291194b96F14136E3459fd2133d64544769D27A": " 200 " ,
     "0x539c16fAB377E8Cbc6F86c7a6c9894ece5c3B1A8": " 200 " ,
     "0xF97E32229d9473094D3587C47Cb134467D54B9F5": " 200 " ,
     "0xD8924c698B8A674a1e3d71C457633bA9F178F907": " 200 " ,
     "0x62DD33f9ED4B1E2d0baaE9F97A2aaEd0A63cDFb3": " 200 " ,
     "0x9eEd6caBCe8C37528325806E7d15dc87E947D3dA": " 200 " ,
     "0xdE51d484F603e86b138C1198822f32c7D796CA19": " 200 " ,
     "0xbDBdce8e0BDEa39D46417EAb7f00f82e2634d625": " 200 " ,
     "0xA9ddcE31856aA17971a2C46b1df5792f349D1776": " 200 " ,
     "0xAb2d6679dB755eD5d4b306DE427fd2d482b49e46": " 200 " ,
     "0x202fb30c0ed387162f5b407d96a47ae09add731b": " 200 " ,
     "0xd9930c14c573ce14aa2252e8b1e7f02da20a67c4": " 200 " ,
     "0x5fd167ee529eb271e9c923a370fcbdf0e937dbe0": " 200 " ,
     "0x88c79e40679a119383c99976db9149e864f14368": " 200 " ,
     "0x042b2eaa31a85af045ec67cb861008a9273dbf5d": " 200 " ,
     "0x20c15bf9ec1e293322312d26a9f868d9706d20e1": " 200 " ,
     "0x9b9de80872bd75ecc53e4a8f5011f0895247d386": " 200 " ,
     "0x80272099b7315575acde1b162c903fa54497ade3": " 200 " ,
     "0x542a7bac257e23433dbab0866645e5b106d8469a": " 200 " ,
     "0x17Ca77Ec9DC0234EeD9A58E2F05E3C163C3Ab176": " 200 " ,
     "0xDBE4981F4e77eCBa5e85829111bC95e7005739c5": " 200 " ,
     "0x6bd101F3E7CABddB20C22f01C69FDf35BA2C9d00": " 200 " ,
     "0x3F5f6d2FBA2d87669831f53cd6d5BC72A3bBdEc1": " 200 " ,
     "0x53dF62A78bFB6AE9dE2896717921a03057446f93": " 200 " ,
     "0x21874ED365E0302D223EEa3917E09C2cc8b37Bc8": " 200 " ,
     "0xb295409735D261959c4274449faf78f9D75BC496": " 200 " ,
     "0x3EC0A4Ea28B3b7b6692443D23F1A1976BaaD89F5": " 200 " ,
     "0x0d6A42558f44279dcfA8f81D22f28794Ee423244": " 200 " ,
     "0xcD316BE05431A615E2C7C6A8E0d36C365D45c26a": " 200 " ,
     "0xdD4373fc7CF24A82b7481e05A34ac9052eE2f492": " 200 " ,
     "0xDB94fD7d0C32a5490F1670D3A8ed136957422C90": " 200 " ,
     "0x674092069ac4f1E6Ec911af630eB3cA05DFd08f9": " 200 " ,
     "0x04B95E1d7dD1d7EF2bf988D2064C5A663E59895F": " 200 " ,
     "0xaadeFc13120152050bE73E7381AD632e2a5D968c": " 200 " ,
     "0x2b154C257ab5F5df9D72D529d37e6720E5D5491e": " 200 " ,
     "0x82671c20bB8287EC99f00A515bF571d9042c05C7": " 200 " ,
     "0xef9fab42D94477F18F9ddc9244C2347bc9536572": " 200 " ,
     "0xC4532b3751faA30Cd24C9C91ca7534BE13c8c462": " 200 " ,
     "0x3B9FCCd925E2CC36496CC39fC30A91Db2FCa23Ef": " 200 " ,
     "0xD9e5626eDa1A7FA68c56aAa7fa67E9ff229FBCB7": " 200 " ,
     "0x7738B43CDfD4E95f6D6d2D621Ca0bdc83b93C65d": " 200 " ,
     "0x30E0bA8c9a133853303f3502a00Af930c0454379": " 200 " ,
     "0x01FCc42CB7e8e0D3C5E50534F60949b63F3bB20E": " 200 " ,
     "0xe9c1D9D09935D3A5a1Eb665bF7079068C46Ac722": " 200 " ,
     "0xBA4e66b5C01Ea3218385b3F5F08D1C490a5Cf3b0": " 200 " ,
     "0x94958F15A6532396029d63f857BC44050A37c3fA": " 200 " ,
     "0x1F432c48635C1bce0Adc217D56FA273F98A39EBE": " 200 " ,
     "0xB51ede72080C0a57d9a7a6a6cD7658DC8cc338EA": " 200 " ,
     "0xAb462292784C813cF294698E9bEd5B8C4d8289F1": " 200 " ,
     "0x532a7C3bC2DD14E8b1790f500dCE123eaC5b4B46": " 200 " ,
     "0x066D364a0714B5c892005Cf6f4326fdA96E4a576": " 200 " ,
     "0xC3CD6EAa43FE866fF3c765D27775608efB086Abe": " 200 " ,
     "0x927BADcA3e807eB64D0812A701e61fC6fAB753a7": " 200 " ,
     "0xDC03Ed2ac4F6D1611c98e717f88cC268d767C8Be": " 200 " ,
     "0x3d98Fb369D69E79803B99BEc14C4a32f3D9b150D": " 200 " ,
     "0x6a7bcBaE98D2e410240045b6e6F2280E9d152365": " 200 " ,
     "0x9881C3ccbF6B5C5376C0F2a34388fe68Bf0A6B0F": " 200 " ,
     "0x58F5AbBE8e0ED6D2aDfE5123E570F14A76f4e444": " 200 " ,
     "0xe6DF59b6504aA9a230772f710fcb2FA9a0560bdc": " 200 " ,
     "0x415f909D8Ba3cf5d24D9eC1a28989fb39F7cccAD": " 200 " ,
     "0xaE2Fb8E5664A3aA84e8B803b131A684D0FBA3422": " 200 " ,
     "0xEf5762E72Ffbad5144a348847b2341560AdA36c0": " 200 " ,
     "0x004db35b82cdAbc3F9817E689b434A07cC613E29": " 200 " ,
     "0x100DD1fA4CD832Da807A3602aB9fFB0C679a3Cbc": " 200 " ,
     "0x218C3790b16F7456C98FD5F7d6e05bd1D636e8D1": " 200 " ,
     "0xf6e546668c397dcb64767efe331904Ba147D5025": " 200 " ,
     "0x2e12d1E2897F32dEb85a248C6264B5E9bA1802ab": " 200 " ,
     "0xA6D9ECd94f259dc923F0a253DfBd395De2C8b4a9": " 200 " ,
     "0x0A27FB641B4E2aA9aB98F5620Ab6cE3dE1aA7B87": " 200 " ,
     "0x80ECC8F7508e71D273467f43B028B604083FD785": " 200 " ,
     "0x9F8E80C6A57f7b58f3Ccd9e412c37597350E5f26": " 200 " ,
     "0xf17be40106a60A0517657df56d5c9F337eeE21d8": " 200 " ,
     "0x2c322553e48eBA784924316CC8Ee5362642E49f1": " 200 " ,
     "0x0CdaC929e84E22b4508be49b992FbA4690e4C2d7": " 200 " ,
     "0xC06Cc30283CDA0456f83CdbCC77fA704De1DED62": " 200 " ,
     "0x729405b10734D804C0942Fde42B80080c5C4324C": " 200 " ,
     "0x58C2CC41A4633493036D8dcf86322D0f9398f2c4": " 200 " ,
     "0xd5f34C270C752609298D1Ed6007414577C12B9A2": " 200 " ,
     "0xA7aCDBb874A063633F264A868bED3986e736361F": " 200 " ,
     "0xA2A1247FDF815F2c88AE0D163578A204c1c5B6d8": " 200 " ,
     "0x4b6c53f7a1856bc377035B7076eAAa4743bD9174": " 200 " ,
     "0x54386E0efC858A4744AA67F46B50917cf1f7820f": " 200 " ,
     "0x4Bf57B5E2A7bc7d071a2A070B0735F1506B89b77": " 200 " ,
     "0x09F3d94b263bA701d798452c26bAedc32c8fA8e8": " 200 " ,
     "0x78a58a0B73D85c8211C8E12D48d4228B8c098fE1": " 200 " ,
     "0x0751795b014b9F99eC898c176C52b4C44788Ef7C": " 200 " ,
     "0xd6B8E11e78Caa6C6dCE5b6A02689EEda0325f986": " 200 " ,
     "0x19bC3ED78F1F0e27f112ce236d5B384d82DC6888": " 200 " ,
     "0x61C22879a10a20F02a210F64E63A25043B6e1eA4": " 200 " ,
     "0xb2eb3e54b2607F3695Ae18fc5C38CE7BF120CD4a": " 200 " ,
     "0xb95Cfc1939048536AbDaeDFd32c158f005a40dA7": " 200 " ,
     "0x79fB621EF1d87F0686Bd44A0bd8AF6E6213Dd8F2": " 200 " ,
     "0x4F84d0B27a8e5fCa96e4B56785Bde8816f04A17C": " 100 " ,
     "0x9d0061D4965d3DC83833DD43a9A709EcF36284e3": " 100 " ,
     "0xC7746A28cFfea14Da136C55774A639AE5C6163b1": " 100 " ,
     "0x5cED74603189880B02018c4CC1E8CF19463dfC68": " 100 " ,
     "0x7404eED5265b8d1B809b96Fa272A1591D6c17c03": " 100 " ,
     "0x9EF358eC67eb85e86d9e118d15725522c9A7C82b": " 100 " ,
     "0xbb1b4b348dad4ba1414c37fbc3ae8e567dd16ac7": " 100 " ,
     "0x04554b17deBbD7227df76126922ff50108A6F218": " 100 " ,
     "0xecBa344b620Dc7f736a2bdFEFa95dBA14BE29023": " 100 " ,
     "0x7F21B3E952ae82d10F66E03a9B39D198728A31E0": " 100 " ,
     "0xbC8D11185FC39F0f877076278DB21f76777be3c7": " 100 " ,
     "0x8F99755CB93d7d6eAa0d006Ad3736B67EefC3Fe9": " 100 " ,
     "0x0449033f3dfD16607289A94eD9751d59c3815762": " 100 " ,
     "0x94591020D235aE6582AF14a95cb75A8196024751": " 100 " ,
     "0xd12Fc5b97F48A1FC617D98F3F359dB8DC28D9789": " 100 " ,
     "0x4Dae657BA4A95478eE3dca17D03928b7c66ac87c": " 100 " ,
     "0xAb7734822029Bc366103c37BcB7204Ca5d3aCa56": " 100 " ,
     "0x25Fa2F0bC0DddC17188a51298d0060b74067e4C1": " 100 " ,
     "0xD1aC7C1B31677Ad5D7D9c0a8F85357D19e856445": " 100 " ,
     "0x8541883f909d68eFe28bA23241C09F88E48d4c79": " 100 " ,
     "0x166401Cb29917B5432212354E96DaF210e2684a1": " 100 " ,
     "0xB88D3d745Dc91F0412C117d6D10d679f48430557": " 100 " ,
     "0xD6f9b7F0CFD85d3D53011bbe583f973F682D7586": " 100 " ,
     "0x7d1202d16377679827eA3f08c5D84B0eE5b49c3e": " 100 " ,
     "0x3808965c73f8491D966c4Ec6433f3b972d210C86": " 100 " ,
     "0x14E96fC87dedEb07225B89614d17768C9FC07F04": " 100 " ,
     "0xF07492b659719eE8170de1d0d422Cb17Ec9b9606": " 100 " ,
     "0xfdB708b3736A5a3DbB690dB6cF821bD94CEe5036": " 100 " ,
     "0x84537E1F1ae3EF5d44195C62C4F4Af8a5D8b048B": " 100 " ,
     "0x329fCBeedDce042fF7Ca06369d2049dAFFE153d7": " 100 " ,
     "0xEaabbB939F04680875a146aAac671f644E332f2f": " 100 " ,
     "0xc64DB76467eac2CD8Ef69c8a2f7EeB6A49F3C2f5": " 100 " ,
     "0x57363DAE428FCe940440a604b0ED426fDe72B908": " 100 " ,
     "0xC0fcEfc72143ff58Cae05F4AFA74D70F8BD6d395": " 100 " ,
     "0x425728A272CDCb69bCe88306Ff24F2048d82a96D": " 100 " ,
     "0xE94402cC574eEdf50685437Bf0c5574930B8b253": " 100 " ,
     "0xA91E62465F52eAf0B61BbEFaDb081344B257d529": " 100 " ,
     "0xe7162333F3cC81D2e585a31CB08A0BE83Fa2c52f": " 100 " ,
     "0x3C61507A7283Ad0D3eA9C8cF2F52f88ee22b14D6": " 100 " ,
     "0xBDACe52Ae017E0E42BFA64505c98623A917F8bfe": " 100 " ,
     "0x89f287bc72CF084d4eC099CCD557Adc624E14037": " 100 " ,
     "0x583aB2F896997f5F215882C8a4bbbCb5E829C4b1": " 100 " ,
     "0x36b7de98140c20059cb8fe29201f76102e0a33e5": " 100 " ,
     "0xd02d96900fc1f58ca12b65cba9c2a377c5ed706a": " 100 " ,
     "0x6f9D64f3b2278AD359a5E84C4DA388F5De0B196a": " 100 " ,
     "0x2657CF5Cc08f298eFD7310653B9D3Bdc1fCcb440": " 100 " ,
     "0x9DCdd8a22c21942c5C5F82e053B5fccf814dB47E": " 100 " ,
     "0x87791D582638bCe3872c3DDaf7131BfA95273C87": " 100 " ,
     "0xd2AbEDd7323BFd261745556688e547b54F4C8876": " 100 " ,
     "0x4DDD07b98fc91A8495389B78Cc38f1Eba265dC87": " 100 " ,
     "0x186Dd847Ad6f3cE030C2c44Cb8EDA8C08c289037": " 100 " ,
     "0x99Ab48B01285474f677a7C79daf60375EBd88D79": " 100 " ,
     "0xB41870423E4DC5E5E3cd86698bab4E98ABA4C85A": " 100 " ,
     "0x44843b676E8B66CFf9402711023884Fd2D4913D8": " 100 " ,
     "0xbfeab5493ee87d29af2328b3f4943bd8097ae526": " 100 " ,
     "0xbfd919ff6baf603cd486a7481e101ddbacfbf346": " 100 " ,
     "0xfBA165bf2b5b3cf609763ba680ADb9A372A7dAe3": " 100 " ,
     "0x181d3d058D0F6f522c57bFeEf08EEc64145C5D8b": " 100 " ,
     "0xB6825B1d069824678dB46477D2a35d4B6445a66b": " 100 " ,
     "0xb300f39242D2f5D560586C9842875817342f69E4": " 100 " ,
     "0xc9cA00D51b5E56A065d97922ffF86Ff96123685d": " 100 " ,
     "0x7bD08d966c2c74e4dE63109568C1D9D79b2FC7a6": " 100 " ,
     "0x7e2f6e2f86089ae708425bca2e7bf43ad5ca486e": " 100 " ,
     "0x97f460d87bBf31E809F761c708566CEF8407426b": " 100 " ,
     "0xd807F20D3C66721c90823B9A72DCb4AF2e2B6947": " 100 " ,
     "0xd3d10De50a154B42ad6e697F29fb3c9433f75162": " 100 " ,
     "0x31C7067eEAFA8b51Cd73Dc92Eb801B4E1f7c709d": " 100 " ,
     "0xbee6Fcd6aA71028ef6d5b1Af6fc14161CEf4304E": " 100 " ,
     "0xfeF9253CbF4333179fdbBA39FF2e52A9eE47a695": " 100 " ,
     "0x3A6f54c7d10d25bcA9c0fD047fB2c12Cd45223a2": " 100 " ,
     "0x3DBF8D69C6c3F92A456B4d317262abD0B2b72cbA": " 100 " ,
     "0x296dfE4eC6d2DfC8c688F9657BBc42E4f16BeA33": " 100 " ,
     "0x3F0066e2A05283482bC30BD4cDAd73d04E6FE0A1": " 100 " ,
     "0x4EdAEFE0794473f2431cC54eac2d776F092B7e45": " 100 " ,
     "0xbebC4ead3CE8f2f8A7aD4E91918B4161bb0462E4": " 100 " ,
     "0x087786FC86Ba2eAA975908746976019843a90278": " 100 " ,
     "0x527ec113F0243807F743638Dc59088D736119256": " 100 " ,
     "0xc9039701cC0649972bd4982C25b4b649f9eD181c": " 100 " ,
     "0x0736ade83ECF5EB224a4b91f31BA2eFC4680e188": " 100 " ,
     "0xDBEA207d24c73a7218601fa096f1fd7f9861c6C6": " 100 " ,
     "0xaDbEadd84FC0F67A21aE0Cc1c30C518856815E8e": " 100 " ,
     "0xd6Edf6EA9880147e957087A1d878FE544dBC193c": " 100 " ,
     "0x0C91e83C6a3373C02C1107323FDE6ac9737fe31D": " 100 " ,
     "0xEe26F68b2fc03182A48Bf7De872EbA24b67aB164": " 100 " ,
     "0xd625Bee9d3669Fac8BCcd687657149fa499aDEa7": " 100 " ,
     "0x03d300DA2929D860205C0b6702e806Ca4a87979F": " 100 " ,
     "0xC2D999ac781bde9Ee994F57e1E431c7E845301Ae": " 100 " ,
     "0xe6aB4CACB36d314569355b0F4C2a5F87ceabcEfB": " 100 " ,
     "0x695915922cDbd18Ba3957278e2f3b4d0eAb77e01": " 100 " ,
     "0x22ADf839973F58855A7A5a5a40Fb1789b2A74674": " 100 " ,
     "0xdCca39c5117f9Ce2aA0a9ea314C760617A7030dD": " 100 " ,
     "0x4Cd7De984D22201DBb404D251043Ac54460E94C6": " 100 " ,
     "0x1c9d0883A9B9Ebb4719A3e504fE3C164eC039873": " 100 " ,
     "0x03981992d1fA64d5045Dee1c450E77c8FE9665a9": " 100 " ,
     "0x35cF465cCBB00013006FC474bEA273E2dFDeF4C9": " 100 " ,
     "0xbe94e09160ADdE65A9BB48EA5cF579eB8Ad9aE63": " 100 " ,
     "0x7b4fd60173b77669eaf665475A40D732A53D5b10": " 100 " ,
     "0xd6E0ABED5d5EE773aca929D6D0f4e9bd22D339DF": " 100 " ,
     "0xA1691B64f7693781c9DC8179460BB6b03CfDE291": " 100 " ,
     "0x45EAd75372BEA9Cb112810c1d7ce76aC047799EF": " 100 " ,
     "0x08363a9194Fd271fF1E3c2D66DdADFB10Dc868B9": " 100 " ,
     "0xeFd1d3Fb04b75d5233e45Da912B395cD7911ec1e": " 100 " ,
     "0xF5E99D7eb18c6FAa107B1E20d69A2e372e14461e": " 100 " ,
     "0x4D03bC53e008735479De53080594c6fc3CE5B187": " 100 " ,
     "0xA91BD99f8e5f97DBf49526E7D611dC7A0A51c035": " 100 " ,
     "0xea7e683fCd2b3464CFf49E7306db09AFdC58d6Ae": " 100 " ,
     "0x0Fc4e8bc9129A714F8bf26Cfb609515C55d48171": " 100 " ,
     "0x0BfB19283e15625Ff7923b25A66eD74096e724c0": " 100 " ,
     "0x8447FF53B610091cbc356F65cF930898cC125A08": " 100 " ,
     "0xC0251d2bF61eb2250BFDdA9ED28FF8dF4B646980": " 100 " ,
     "0xbFE2f93f024CF5A6f7eD619316Cfe6ca7dF0A4B6": " 100 " ,
     "0x695F9F35f1c9181ECBAd6d3F274F4b7ECCFee88c": " 100 " ,
     "0x1d83615FCFdb9763F8a4DDa06D60b61160b5C26c": " 100 " ,
     "0xeF8f1ab729BfF21F8df4AF33De3efFF0C9C82D86": " 100 " ,
     "0xC294A5d77B5609a4576b1Fb671A96Ff96E46054d": " 100 " ,
     "0x0F17b988914Bb1794ed73884BF258858c1781007": " 100 " ,
     "0x6C26C2c181199584468a548ff972F3cC06D17401": " 100 " ,
     "0x0DF1b3DE541527F1593c9B3C9D681278895e00B1": " 100 " ,
     "0xCf55130D8321D959E75278d3E2F6C23c675252Ee": " 100 " ,
     "0x8b301D633f9a21c8D837c35813E6A079Ef159bF9": " 100 " ,
     "0xee48bAd80E243f61A7A1F09790E5604703758F4f": " 100 " ,
     "0x35C58Ce57e015504Ce91a60c8e8784cf3959ccA9": " 100 " ,
     "0xEa3deB4bD6B5bf7bFfCC2991878D4FC03aD95725": " 100 " ,
     "0xd8F54279E0f26d7597066BE662CcEB833c6397CF": " 100 " ,
     "0xF4a9D0b961f562220DAF546166f12a6D74783323": " 100 " ,
     "0xd2850effEf33e0895d249d38719834D22867008c": " 100 " ,
     "0x487a8E4E49B210E97B9c07a1b88C67491C633840": " 100 " ,
     "0xe73be4748b096283ea478E8a556b2255Fe5C7676": " 100 " ,
     "0x3841aA47CE88104D2fF59BFAB044c3aff5d75f2F": " 100 " ,
     "0x5E5d033b54a075f2474477C7F9240A7Fe169FF90": " 100 " ,
     "0x5cBE61f83a7E078fa13d773f6B5De075cB532855": " 100 " ,
     "0x3cB6bac3ddDc7c8b525427cf2AdBf32A42506C3f": " 100 " ,
     "0x49f6F614b51d4576D4BA17A1cE0DFE015C19f3a6": " 100 " ,
     "0xC597E923Ec3aCd0E4131C785B223997fc840Ea86": " 100 " ,
     "0xB47cAb99dEe9782349cfB85e17fa7d7051C489f1": " 100 " ,
     "0xDF9dfd50Db9dDa6aA0A757272F69FE200ea1fC0d": " 100 " ,
     "0x7031435A19F0e7c9de918F661abB6d60FaE3f21D": " 100 " ,
     "0x4285422D0303a25aA840341aF1197D8F38D23CF8": " 100 " ,
     "0x2c4C618d5f34793FE0ccDd342a176ED721bD4c26": " 100 " ,
     "0xE4bAe875eA2D0aFe9308CD630622E1535b71Aeb9": " 100 " ,
     "0xDf52287BACd44D40492A3F530c49139b5Bbc03DF": " 100 " ,
     "0x0ae757C251D7a51e25e200b95557a5d5b6A19915": " 100 " ,
     "0x9617C0f8931d221da5176Dc8c955a430f48Fd296": " 100 " ,
     "0x6F43E2aacc44Aa061732C16CD719c16De7485a86": " 100 " ,
     "0xba14c31822CE7C7CE3e334c5d7C94D2dB7DdBBf7": " 100 " ,
     "0x3617B48663805b40c45F58fd956464F52e0Dfd2d": " 100 " ,
     "0xeB77ccEBbc85Cc843f5d9c9935D7343e70d2e15C": " 100 " ,
     "0xBdFe9B70b0c02D18ff5b8821E60EF640bA7D7D50": " 100 " ,
     "0x4a03946D1CD375C3651ca331F5DAf912Da2cfCD9": " 100 " ,
     "0x92b5136533bF6e254c9a5d7cBaB609C2942569A9": " 100 " ,
     "0x32fE260cf4b1c34D6274e19D1073F9567CF566F2": " 100 " ,
     "0x4b86d152A08a60c9f322A3377F971B3FD27e1ced": " 100 " ,
     "0x16B27c92EF19F765167663CeaC9F5ecd6B604fEb": " 100 " ,
     "0xCD3760d61F8BAbdAacBDe815955038e57b50F45C": " 100 " ,
     "0x745C0e58Ef86f8842cBA5EBdc75C503ff6c33B03": " 100 " ,
     "0xa491600E2DfEb1FC199f556D92faC5BF0E780BbB": " 100 " ,
     "0x3574e417f432c58Ec1138d226ec3b170e4471C0B": " 100 " ,
     "0xab8Fd4C773F79eb6fCe87c0612b0473b2a408e70": " 100 " ,
     "0x97E2aEE0e9db2697eA71694a58d34A8ffffB2c66": " 100 " ,
     "0xE80Dc71B16C059Edf302e1d5B151a537696E03dA": " 100 " ,
     "0xe4aC5Fe2d372d5dFe87c19225aFb9C7978DcBa60": " 100 " ,
     "0x64147bDFC9e27ef284D16C7d0668aa2Fb392BE5c": " 100 " ,
     "0xd2BeFb8BAebd7020cB8f6d99C65E017B8D405a4C": " 100 " ,
     "0x0B07113995bb66FaB9BF317ab83e2407520fda8f": " 100 " ,
     "0xf08951945CdF9D56bD82A639EA895e72c543EF35": " 100 " ,
     "0x63B0B62d3A25BD7C4A06E7a482dbb3Fc0a9B0335": " 50 " ,
     "0xF7deC381A89c9D9085df1D1E40412629DE6C92c2": " 50 " ,
     "0x85e554B6380dbDB191311dcBE0E6c90D4d21fC98": " 50 " ,
     "0x91eBd60b4457A393F70779Ba20EbBA0550e473dE": " 50 " ,
     "0x4AA8546AAfD077F6e65be7FD4a786D1F7542c66c": " 50 " ,
     "0x8152a383E33e11209F97bA03Fe453519EA1EaaAA": " 50 " ,
     "0x97ccf5672A8b37C566203bC80A3057dDA29cE8bd": " 50 " ,
     "0xaEd9393a50E869EA87b7F7b30E6B0b483671bb0B": " 50 " ,
     "0x6eC6dfbf9d18eE791F947f680520366610b3b7d7": " 50 " ,
     "0xe44782AFE398800d7190796018C4b865E32fF806": " 50 " ,
     "0x6f797F8B349b34821D8Ec65f3FD754E9c60cFdE6": " 50 " ,
     "0x29dB7f6deE7EcceCf79DA574EdFEe51FD73fFFE0": " 50 " ,
     "0x67D47A8812159777711e7334F6d578A3F77180B8": " 50 " ,
     "0xc918E95EaeB923A05d2D87A222066ea89192b08C": " 50 " ,
     "0x59717BB6A7414f959Fa70F8Ec70a96909054b7db": " 50 " ,
     "0x1F97d6Db8b9c10601EBaf9031dAbD9d58Ab2D695": " 50 " ,
     "0xA12D097a0cA01857E9F1b81FC92F4015fcF61cD7": " 50 " ,
     "0xDD91872feb1EcdD7eeFe27E669fdff321AEa12df": " 50 " ,
     "0xB0574EE952F53F65E738f2370a70625f986EeFb6": " 50 " ,
     "0xc6ab6f6fB215307936B3E93fc5d8AFd16678fBe9": " 50 " ,
     "0xcC2F216E26DcEB9A045Bf2f7105137a7C8ee3F84": " 50 " ,
     "0xDa1521c966bc95324E156f4F04B28F2804985da5": " 50 " ,
     "0x20327f224E7Ad5a08035fdAcc68429143604BE25": " 50 " ,
     "0x3F4c541450a12d0d37CA6DdcA9c6C057A80Aef82": " 50 " ,
     "0x46F6Ff7E9fF1aA4c0F368E5a1d9e9DF4f730BcB6": " 50 " ,
     "0x12f21b16b873712606b08537e2698F7F01D668eD": " 50 " ,
     "0x6Dd58A6c783e3d7A9C2Be6267169CbD973c146c3": " 50 " ,
     "0xe032c7Fcb3390473509579f85389eA910334A98E": " 50 " ,
     "0x9e3eda2bDdE703669286120D3a91a21a6c657bac": " 50 " ,
     "0x681fad990a5Cf4386bA1DfBC294f3c4284d506fc": " 50 " ,
     "0x10f02554fa1493aF8f35dA73c7b3fF47Ccb95c71": " 50 " ,
     "0xA8CfbB2181C492c7eE9f3089DD1DaE64d5031282": " 50 " ,
     "0xeB411D7Daa4136831D391559588e31Ea5BdfD225": " 50 " ,
     "0x994F4Fd09C3922D936CC43Ffcb848333328b48AA": " 50 " ,
     "0xa73018C8c64D8b58f692da632255C8ac907C8d58": " 50 " ,
     "0x80318Ef43B9D2ab07bbDC8fC38264020da45d2e0": " 50 " ,
     "0xa698119Ac48340790d831AAa00bf1834FA6b9eED": " 50 " ,
     "0xB42fA15fCA3d99D6df107Bfaf4448ff4892F909E": " 50 " ,
     "0xFd364D9a189c300D7b28ab9Ca511d467d15dfd76": " 50 " ,
     "0xA33A19be0e183490f1edcFb54ed55e5065286a50": " 50 " ,
     "0xeBe2ADcBc2448787EDffb030307BeB8eFBA583DA": " 50 " ,
     "0x2145ac607A1b97D28BE117D06474B003deC986b4": " 50 " ,
     "0x4a5C3247bcDa98BFB7aA5E92401A6346Fadcfe5b": " 50 " ,
     "0xf783A35Ad04b6fAc6598079BC7A0B1023Ba5B355": " 50 " ,
     "0xECEFC90F540329d8d89bCeD4b7F61666554c04A7": " 50 " ,
     "0x3946F1431Fc2c7c1C8D2176db3f932c03095651E": " 50 " ,
     "0xE480FE484F94Ae31297c9Dc74d1f884Bc0985800": " 50 " ,
     "0x0e4e01FBEf1a828cA854623853DD6A80324cE012": " 50 " ,
     "0x26e174dC8FF20831EAA75fEf12822e371AD662e7": " 50 " ,
     "0xd4200cDE10398D566f5766098CBfc170Bd61510a": " 50 " ,
     "0x92F2AC81D92748E1Ff474DdD98E713D9D7237589": " 50 " ,
     "0x65D9Ce85cEA67671a9A8D9f68eA9CA03E9491707": " 50 " ,
     "0x0cE84d38eCD250D73c1402655Ef273a203d241ac": " 50 " ,
     "0xe98F2e5DdAF23A69B20260eEDB3B30b2cE8B3a2a": " 50 " ,
     "0x1BfB28B94BB6dE53779c2c4eC289BCe37745016a": " 50 " ,
     "0x855938645c811e471891fbF3Fe31922722311E67": " 50 " ,
     "0x3A55E080D4109F68709D9cAE342f2719dE6860ce": " 50 " ,
     "0xa77831300bA5bA3230E136F135C0917498EFe02D": " 50 " ,
     "0xaaa527eD381E439047798C1eE018465224919b65": " 50 " ,
     "0x30A3c739A384Ffc4224E00D3FBF9D38616666634": " 50 " ,
     "0x9Cc771519714D27dCD25d71dd5F32c7144F36a89": " 50 " ,
     "0xd7F6bad6Fc716b889F84a94fEE046188f08988ce": " 50 " ,
     "0xa84F65179D60CDf56E0C2De99527DE460b7Ba1Ec": " 50 " ,
     "0x356B9E6324098a67119639B0A6d176F3C3a546cA": " 50 " ,
     "0xD99E943A86c78eFe52118c63814b808371b9b5bd": " 50 " ,
     "0xdCdf6ef3a314767750e98178f5553081e55BEd5f": " 50 " ,
     "0x496B92bfb6f1e8647368a0e0282cFF655ed9beE7": " 50 " ,
     "0xa4F8322E04f38F1B60E44f3f5E9B0a54f34d3aF2": " 50 " ,
     "0x2325d89c204aCec51e94DAb5Dc8Df8B9B0cF7243": " 50 " ,
     "0xe96778F81502A697836Bdd53268Bab8c9cD9593A": " 50 " ,
     "0x468E36764a5bbA5cD6128578DA1465ED9a2c3462": " 50 " ,
     "0x8Dfe72f17A0d110CCa24A8c91b6cD9D795258187": " 50 " ,
     "0x67Ae297A061a6b54ab82261f2DEDa96005fA292A": " 50 " ,
     "0x5f69F68Cb305F290B67CE4f4c0Aa9090bc68B7B5": " 50 " ,
     "0x52aa6B2b10ccBE35A7669032f7185b0E22FE85bf": " 50 " ,
     "0x1A7BbC187B5a3385D47Aa751Bd08e104a4f7108b": " 50 " ,
     "0x02B0050e8341c1Ec6e2182fa52128cE307B9C1d6": " 50 " ,
     "0x28dd307aaE11275D9e6dfF41CA8E127430E058D7": " 50 " ,
     '0x86D8B774F9afcFc3fdC59B267A7d9ECFA9A2627b': ' 50 ',
'0x78434F8E0526344405a046AB6AE3B5b5b36905be': ' 50 ',
'0xD4fd155cD2c2253abBC3F4f5f4FBEF6ab7EfFc08': ' 50 ',
'0xDcd7A0185d8cE35ee97e60168Fb2B29A108e41c3': ' 50 ',
'0xd506DfBB2b50769fDdAb2D6493ac6D9555957809': ' 50 ',
'0xE34Ee53f045293Fd21AEA306C33CB862F5ec8EB4': ' 50 ',
'0xbBec4Da79C36aFa1125DA1e962D7349C1aDbFbaB': ' 50 ',
'0x55c1A5aF0A90F047EAB3f487B1757Ea7Ed595af0': ' 50 ',
'0xE0afFaCc943ad8a45C19306fCf1053B4C103bD48': ' 50 ',
'0xf9BDA8d2A24343e44cA1181Ea20D983123DbD50d': ' 50 ',
'0x09C6230e43aCDF4aA89CCAB18C5cFD3F780459B7': ' 50 ',
'0x1981f61d8D6156F54747ddDcF6E1608E94eC3784': ' 50 ',
'0x7Fb7Ba692d9213A3900c005eAd2cfAd86C2970d2': ' 50 ',
'0x326e52cee0b8Bd8Fc13faD3f69782c0Fc0514Ff6': ' 50 ',
'0x303300796db5c04D05Eb6EE02c7dD78f775f9471': ' 50 ',
'0x031308cca42499D636E88051aED00cA79C9bEae4': ' 50 ',
'0x67bAe3D008d8870F3548A8203884EbfcBE5753ff': ' 50 ',
'0xd5Bf6589bc59Dca42f672136AC0458a0B8088aC8': ' 50 ',
'0x671789AbFa7018d7f2CC649E44Fa6fB48f4817bC': ' 50 ',
'0xD7881AE58FdeA81768936360b49187a3dBe970e1': ' 50 ',
'0x144570e98961298DAe41841A971b646F143DfdE1': ' 50 ',
'0xDCCD872Dfa4bE693bE6Fe2b8AF07e425283C1902': ' 50 ',
'0x4a115Bd84AF3dCAAE41f46E90Bfc0975961774C1': ' 50 ',
'0xE074405781cF33d3cE535B9671b16f1b46ABd5f2': ' 50 ',
'0x5d86A3808b8fE875d1cd2beE7A8127234f55c4AF': ' 50 ',
'0x8A8A0197364ad87eBED73f609D6792db9a18C796': ' 50 ',
'0xeF09814B6d1a924AA14a5950B82954ACd9e470B6': ' 50 ',
'0x9dD8BC5Ec3E4c12cBA4E86fe32f2e98b5B0C31b5': ' 50 ',
'0x8D41a8fC6c7a6f07Ce60B421f0f24c958e09211f': ' 50 ',
'0xfd55d3473f351692A6556B29be0276742027727b': ' 50 ',
'0xa5c324Ce13f85f79C9be8dEC5815b2283EbaB070': ' 50 ',
'0x65d9DFA3406D1A6Fe9643742AaACD7d9b6a14c34': ' 50 ',
'0xAFd12aD4baf2c15D1770D400811EEDa512fd442d': ' 50 ',
'0xfbB699E4E246e32796674FDbC34efb471b5Bc394': ' 50 ',
'0xFF89181237461a99FbD4D1318b5121A6FdB94606': ' 50 ',
'0x2B99C2968Bfd54dc5AFF2b606B3231F1DB801FC3': ' 50 ',

}



const whiteList = {}

for (let key of Object.keys(wallet)){
    whiteList[key.toLowerCase()]=wallet[key]
}

export default whiteList